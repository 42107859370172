import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { map } from "rxjs";
import { UrlConfig } from "../config/url";
import { CashbackUpdateRequest } from "../model/request/cashback.update.request";
import { CashbackQueryResponse } from "../model/response/cashback.query.response";
import { CashbackUpdateResponse } from "../model/response/cashback.update.response";

@Injectable()
export class CashbackService implements OnInit {
    constructor(private http: HttpClient) {
    }
    
    ngOnInit(): void {
    }

    query() {
        return this.http.get(UrlConfig.cashbackQuery).pipe(map(m => {
            return new CashbackQueryResponse(m);
        }));
    }

    update(request: CashbackUpdateRequest) {
        return this.http.post(UrlConfig.cashbackUpdate, request).pipe(map(m => {
            return new CashbackUpdateResponse(m);
        }));
    }

}