import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { EmailLog } from "src/app/model/email.log";
import { EmailLogService } from "src/app/service/email-log.service";

@Component({
    selector: 'app-email-log',
    templateUrl: './email-log.component.html',
    styleUrls: ['./email-log.component.less']
})
export class EmailLogComponent implements OnInit {
    tableDataLoading = false;
    isUpdating = false;
    listOfDisplayData: EmailLog[] = [];
    emailLogList!: EmailLog[];

    constructor(private emailLogService: EmailLogService,  private notification: NzNotificationService) {
    }

    ngOnInit(): void {
        this.tableDataLoading = true;
        this.emailLogService.query().subscribe(s => {
            this.emailLogList = s.emailLogs;
            this.listOfDisplayData = [...this.emailLogList];
            this.tableDataLoading = false;
        })
    }

}