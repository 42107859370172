<div class="center-container">
    <div class="otp-container">
    <form (ngSubmit)="submitOtpForm()" #otpForm="ngForm">
        <div class="form-group">
        <h2 style="margin-bottom: 20px">Doğrulama Kodu</h2>
        <input 
            nz-input
            type="text"
            class="form-control otp-input"
            name="otp1"
            [(ngModel)]="otp[0]"
            #otpInput1="ngModel"
            required
            minlength="1"
            maxlength="1"
            pattern="[0-9]+"
            (input)="onInput($event, otpInput2)"
            (keydown.backspace)="onBackspace($event, otpInput1, otpInput2)"
        />
        <input
            nz-input
            type="text"
            class="form-control otp-input"
            name="otp2"
            [(ngModel)]="otp[1]"
            #otpInput2="ngModel"
            required
            minlength="1"
            maxlength="1"
            pattern="[0-9]+"
            (input)="onInput($event, otpInput3)"
            (keydown.backspace)="onBackspace($event, otpInput2, otpInput1)"
        />
        <input
            nz-input
            type="text"
            class="form-control otp-input"
            name="otp3"
            [(ngModel)]="otp[2]"
            #otpInput3="ngModel"
            required
            minlength="1"
            maxlength="1"
            pattern="[0-9]+"
            (input)="onInput($event, otpInput4)"
            (keydown.backspace)="onBackspace($event, otpInput3, otpInput2)"
        />
        <input
            nz-input
            type="text"
            class="form-control otp-input"
            name="otp4"
            [(ngModel)]="otp[3]"
            #otpInput4="ngModel"
            required
            minlength="1"
            maxlength="1"
            pattern="[0-9]+"
            (keydown.backspace)="onBackspace($event, otpInput4, otpInput3)"
        />
        </div>
        <h4 style="margin-top: 20px; margin-left: 10px; display: flex">Kalan Süre: {{ countdown }} </h4>
        <button [nzType]="'primary'" class="login-form-button login-form-margin" style="margin-top: 10px" nz-button>Giriş Yap</button>
    </form>
    </div>
</div>
