import {NgModule} from '@angular/core';


import {NzButtonModule} from "ng-zorro-antd/button";
import {UsersComponent} from "./users/users.component";
import {CommonModule} from "@angular/common";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzInputModule} from "ng-zorro-antd/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoginComponent} from "../auth/login/login.component";
import {NZ_I18N, tr_TR} from "ng-zorro-antd/i18n";
import {PagesComponent} from "./pages.component";
import {PagesRoutingModule} from "./pages-routing.module";
import {NzLayoutModule} from "ng-zorro-antd/layout";
import {NzMenuModule} from "ng-zorro-antd/menu";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzTableModule} from "ng-zorro-antd/table";
import {NzPopconfirmModule} from "ng-zorro-antd/popconfirm";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {HttpXInterceptor} from "../interceptor/http.interceptor";
import {NzTagModule} from "ng-zorro-antd/tag";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {UserService} from "../service/user.service";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzSelectModule} from "ng-zorro-antd/select";
import {NgxMaskModule} from "ngx-mask";
import {NzSpinModule} from "ng-zorro-antd/spin";
import {NzDescriptionsModule} from "ng-zorro-antd/descriptions";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import { CorporateComponent } from './corporate/corporate.component';
import {NzNoAnimationModule} from "ng-zorro-antd/core/no-animation";
import { CampaignComponent } from './campaign/campaign.component';
import {NzPageHeaderModule} from "ng-zorro-antd/page-header";
import {NzBreadCrumbModule} from "ng-zorro-antd/breadcrumb";
import { SupplierComponent } from './supplier/supplier.component';
import { TransactionComponent } from './transaction/transaction.component';
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {NzTypographyModule} from "ng-zorro-antd/typography";
import {NzAvatarModule} from "ng-zorro-antd/avatar";
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";
import { DashboardComponent } from './dashboard/dashboard.component';
import { CashbackComponent } from './cashback/cashback.component';
import { EmailLogComponent } from './email-log/email-log.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { PushComponent } from './push/push.component';
import { SurveyComponent } from './survey/survey.component';


@NgModule({
  imports: [NzButtonModule,
    CommonModule,
    NzFormModule,
    NzInputModule,
    NzTimePickerModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    NzButtonModule, NzLayoutModule, NzMenuModule, NzIconModule, NzTableModule, FormsModule, NzPopconfirmModule, NzDropDownModule, NzTagModule, NzDividerModule, NzModalModule, NzSelectModule, NgxMaskModule, NzSpinModule, NzDescriptionsModule, NzDatePickerModule, NzNoAnimationModule, NzPageHeaderModule, NzBreadCrumbModule, NzToolTipModule, NzTypographyModule, NzAvatarModule, NzCheckboxModule],
  declarations: [PagesComponent, UsersComponent, CorporateComponent, CampaignComponent, SupplierComponent, CashbackComponent, TransactionComponent, DashboardComponent, EmailLogComponent, AnnouncementComponent, PushComponent, SurveyComponent],
  exports: [PagesComponent, UsersComponent],
  providers: [{provide: NZ_I18N, useValue: tr_TR}, {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpXInterceptor,
    multi: true
  },UserService]
})
export class PagesModule {
}
