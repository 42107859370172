<nz-spin nzTip="Giriş yapılıyor..." [nzSpinning]=showLoading>
  <div class="center">
    <form (ngSubmit)="submitForm()" [formGroup]="validateForm" class="login-form" nz-form>
      <!--
          <img alt="logo" width="100%" src="assets/img/logo_dark.svg"/>
      -->
      <nz-form-item>
        <nz-form-control nzErrorTip="Lütfen geçerli bir telefon giriniz.">
          <nz-input-group nzPrefixIcon="phone">
            <input formControlName="userName" mask="(000) 000 00 00" nz-input placeholder="Telefon Numarası"
                   type="tel"/>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Lütfen Şifrenizi giriniz.">
          <nz-input-group nzPrefixIcon="lock">
            <input formControlName="password" nz-input placeholder="Şifre" type="password"/>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <div class="login-form-margin" nz-row>
        <div [nzSpan]="12" nz-col>
          <label formControlName="remember" nz-checkbox>
            <span>Beni Hatırla</span>
          </label>
        </div>
        <div [nzSpan]="12" nz-col>
          <a class="login-form-forgot">Şifremi unuttum.</a>
        </div>
      </div>
      <button [nzType]="'primary'" class="login-form-button login-form-margin" nz-button>Giriş Yap</button>
      <!--  Or
        <a>register now!</a>-->
    </form>
  </div>
</nz-spin>
