import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Cashback } from "src/app/model/cashback";
import { CashbackUpdateRequest } from "src/app/model/request/cashback.update.request";
import { CashbackService } from "src/app/service/cashback.service";

@Component({
    selector: 'app-cashback',
    templateUrl: './cashback.component.html',
    styleUrls: ['./cashback.component.less']
})
export class CashbackComponent implements OnInit {
    tableDataLoading = false;
    isUpdating = false;
    listOfDisplayData: Cashback[] = [];
    confirmModalVisible = false;
    selectedCashback!: Cashback;
    cashbackList!: Cashback[];
    searchValue = '';
    searchType='status';
    visible = false;
    startDate?: Date;
    endDate?: Date;
    approveModalVisible = false;

    constructor(private cashbackService: CashbackService,  private notification: NzNotificationService) {
    }

    
    ngOnInit(): void {
        this.tableDataLoading = true;
        this.cashbackService.query().subscribe(s => {
            this.cashbackList = s.cashbacks;
            this.listOfDisplayData = [...this.cashbackList];
            this.tableDataLoading = false;
        });
    }

    showConfirmModal(cashback:Cashback) {
        this.selectedCashback = cashback;
        this.confirmModalVisible = true;
    }

    confirmModalOk() {
        this.isUpdating = true;
        var request = new CashbackUpdateRequest();
        request.cashbackId = this.selectedCashback.id;
        request.status = this.selectedCashback.status == 1 ? 2 : 1;
        this.cashbackService.update(request).subscribe(s => {
            if (s.isOk()) {
                this.confirmModalVisible = false;
                this.selectedCashback.status = request.status;
                this.notification.success('', 'Cashback başarı ile güncellendi.');
            }else {
                this.notification.error('Hata', 'Bir hata ile karşılaşıldı.');
            }
            this.isUpdating = false;
        });
        
    }

    confirmModalCancel() {
        this.confirmModalVisible = false;
    }

    reset(): void {
        this.searchValue = '';
        this.startDate = undefined;
        this.endDate = undefined;
        this.listOfDisplayData = this.cashbackList;
    }
    
    search(): void {
        this.visible = false;
        this.listOfDisplayData = this.cashbackList.filter((item: Cashback) =>{
    
            switch (this.searchType){
              case 'status': return item.status != null && item.status.toString().indexOf(this.searchValue) !== -1;
              case 'supplier': return item.supplier != null && (item.supplier.toString().toLowerCase()).indexOf(this.searchValue.toLocaleLowerCase()) !== -1;
              default: return item.status != null && item.status.toString().indexOf(this.searchValue) !== -1;
            }
        }).filter((item: Cashback) => {
            if(this.startDate != null && this.endDate != null) {
                var transactionDate = new Date(item.transactionDate); 
                return (transactionDate.getTime() >= this.startDate.getTime()
                    && transactionDate.getTime() <= this.endDate.getTime());
            }else if (this.startDate != null && this.endDate == null) {
                var transactionDate = new Date(item.transactionDate); 
                return (transactionDate.getTime() >= this.startDate.getTime());
            }else if (this.startDate == null && this.endDate != null) {
                var transactionDate = new Date(item.transactionDate); 
                return (transactionDate.getTime() <= this.endDate.getTime());
            }else {
                return item;
            }
        });
    }

    searchTypeChange() {
        this.searchValue='';
        switch (this.searchType){
          case 'status':this.searchValue='pending'; break;
        }
    }

    startDateChange() {
        this.startDate?.setHours(0);
        this.startDate?.setMinutes(0);
        this.startDate?.setSeconds(0);
        this.startDate?.setMilliseconds(0);
        this.search();
    }

    endDateChange() {
        this.startDate?.setHours(0);
        this.startDate?.setMinutes(0);
        this.startDate?.setSeconds(0);
        this.startDate?.setMilliseconds(0);
        this.search();
    }

    approveModalView(cashback:Cashback) {
        this.selectedCashback = cashback;
        this.approveModalVisible = true;
    }

    approveModalOk() {
        this.isUpdating = true;
        var request = new CashbackUpdateRequest();
        request.cashbackId = this.selectedCashback.id;
        request.status = 3;
        this.cashbackService.update(request).subscribe(s => {
            if (s.isOk()) {
                this.approveModalVisible = false;
                this.selectedCashback.status = request.status;
                this.notification.success('', 'Cashback başarı ile onaylandı.');
            }else {
                this.notification.error('Hata', 'Bir hata ile karşılaşıldı.');
            }
            this.isUpdating = false;
        });
    }

    approveModalCancel() {
        this.approveModalVisible = false;
    }
}