import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlConfig } from "../config/url";
import { map } from "rxjs";
import { AnnouncementRegisterResponse } from "../model/response/announcement.register.response";
import { AnnouncementQueryResponse } from "../model/response/announcement.query.response";
import { AnnouncementRegisterRequest } from "../model/request/announcement.register.request";
import { AnnouncementUpdateRequest } from "../model/request/announcement.update.request";
import { AnnouncementUpdateResponse } from "../model/response/announcement.update.response";

@Injectable()
export class AnnouncementService {
    constructor(private http: HttpClient) {}

    register(request: AnnouncementRegisterRequest) {
        return this.http.post(UrlConfig.announcementRegister, request).pipe(map(m => {
            return new AnnouncementRegisterResponse(m);
        }));
    }

    update(request: AnnouncementUpdateRequest) {
        return this.http.post(UrlConfig.announcementUpdate, request).pipe(map(m => {
            return new AnnouncementUpdateResponse(m);
        }));
    }

    query() {
        return this.http.get(UrlConfig.announcementQuery).pipe(map(m => {
            return new AnnouncementQueryResponse(m);
        }));
    }

    uploadImage(image: File, id: number) {
        const formData = new FormData();
        formData.append("file", image);
        formData.append("id", id.toString());
        return this.http.post(UrlConfig.announcementUploadImage, formData);
    }

    downloadImage(id:number) {
        return this.http.get(UrlConfig.announcementDownloadImage+id,
          { responseType: 'blob' });
      }
}