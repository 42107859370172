export class CorporateSaveRequest {
  id!: number;
  name!: string;
  shortName!: string;
  mailDomains!: string[];
  contactName!: string;
  vkn!: string;
  contactEmail!: string;
  status!: boolean;
  contactPhone!: string;
  campaigns!: number[];
  modules!: number[];
}
