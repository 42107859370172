import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "../service/local.storage.service";
import { UserService } from "../service/user.service";
import { lastValueFrom } from "rxjs";

@Component({
    selector: 'app-pages',
    templateUrl: 'pages.component.html',
    styleUrls: ['pages.component.less']
})
export class PagesComponent implements OnInit {
    isCollapsed = false;
    fullName = '';
    @Input() role = "ADMIN";

    constructor(private router: Router, private localStorage: LocalStorageService, private userService: UserService) {

    }

    ngOnInit(): void {
        lastValueFrom(this.userService.userInfo()).then((data) => { 
            this.fullName = data.firstName + ' ' + data.lastName;
            this.role = data.userRole;
            this.localStorage.set('role', this.role);
            this.localStorage.set('corporate', data.corporate.toString());
        });
    }

    logout() {
        this.localStorage.clearAll();
        this.router.navigate(['login']);
    }

    toggleCollapsed(): void {
        this.isCollapsed = !this.isCollapsed;
    }


}
