<nz-table #pushTable [nzData]="surveyList" [nzLoading]="tableDataLoading" nzPaginationType="small" [nzPageSize]="10" nzBordered
            nzShowSizeChanger>
    <thead>
        <tr>
        <th nzWidth="8%">Id</th>
        <th nzWidth="19%">Soru</th>
        <th nzWidth="10%">Anket Tipi</th>
        <th nzWidth="16%" *ngIf="role==='ADMIN'">Firma</th>
        <th nzWidth="12%">Başlangıç Tarihi</th>
        <th nzWidth="12%">Bitiş Tarihi</th>
        <th nzWidth="10%">Durum</th>
        <th nzWidth="11%">İşlemler</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of pushTable.data">
        <ng-container>
            <td>{{data.id}}</td>
            <td>{{data.question}}</td>
            <td>{{surveyTypeWithData(data)}}</td>
            <td *ngIf="role==='ADMIN'">
                <nz-select [ngModel]="data.corporates"
                  [nzMaxTagCount]="2"
                  [nzMaxTagPlaceholder]="attributeTagPlaceHolder" nzMode="multiple" nzPlaceHolder="Üye Firmalar" style="width: 100%;">
                  <nz-option disabled=""
                    *ngFor="let option of corporateList"
                    [nzLabel]="option.text"
                    [nzValue]="option.id"
                    [nzDisabled]="true"
                  ></nz-option>
                </nz-select>
              </td>
            <td>
                <div>{{data.startDate|date:'dd.MM.yyyy HH:mm'}}</div>
            </td>
            <td>
                <div>{{data.endDate|date:'dd.MM.yyyy HH:mm'}}</div>
            </td>
            <td text-align="center" [ngSwitch]="data.status">
              <nz-tag  *ngSwitchCase="1" [nzColor]="'#ffb475'">Beklemede</nz-tag>
              <nz-tag *ngSwitchCase="2" [nzColor]="'#87d068'">Yayında</nz-tag>
              <nz-tag *ngSwitchCase="3" [nzColor]="'#ff2432'">Pasif</nz-tag>
              <nz-tag *ngSwitchCase="4" [nzColor]="'#6f0f32'">Süresi Doldu</nz-tag>
            </td>
            <td>
              <button (click)="showViewModal(data)" nz-button nzShape= "circle" nzType= "primary" style="font-weight: bold; margin-left: 10px; margin-right: 10px">
                  <i class="icon" nz-icon nzType="eye"></i>
              </button>
              <button (click)="downloadAnswerData(data)" nz-button nzShape= "circle" nzType= "primary" style="font-weight: bold; margin-right: 10px; justify-items: center;">
                <i class="icon"
                  nz-icon
                  nzType="download"></i>
              </button>
            </td>
        </ng-container>
        </tr>
    </tbody>
  </nz-table>
  
<button (click)="showAddSurveyModal()" nz-button nzShape="round" nzType="primary" style="margin-top: 10px">Anket Ekle<i nz-icon nzType="plus"></i></button>

<nz-modal (nzOnCancel)="addSurveyModalCancel()" (nzOnOk)="addSurveyModalOk()"
          [(nzVisible)]="addSurveyModalVisible" nzOkText="Kaydet">

  <ng-container *nzModalTitle>
    <span style="margin-right: 10px; font-weight: bold">Anket Ekle</span>
  </ng-container>

  <ng-container *nzModalContent>
  <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">
      <nz-descriptions nzBordered nzSize="small">
        <nz-descriptions-item nzSpan="5" nzTitle="Soru*">
          <input [(ngModel)]="newSurvey.question" nz-input type="text">
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Anket Tipi*">
            <nz-select [(ngModel)]="newSurvey.type" (ngModelChange)="typeChange()" style="width: 40%;">
                <nz-option *ngFor="let c of typeAddList" [nzLabel]="c.text" [nzValue]="c.id"></nz-option>
            </nz-select>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" *ngIf="newSurvey.type == 2" nzTitle="Seçenek Listesi">
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <div *ngFor="let kare of kareler" style="margin: 10px; position: relative;">
                    <input [(ngModel)]="messageList[kare.id-1]"
                    (input)="onInput($event)"
                    (keydown)="onKeyDown($event, kare.id)"
                    maxlength="2"
                    style="height: 50px; width: 50px; text-align: center; font-size: 36px;">
                    <div class="sil-btn-container">
                        <button *ngIf="kareler.length > minKareSayisi" class="sil-btn" (click)="silKare(kare.id)">
                            🗑️
                        </button>
                    </div>
                </div>
                <button *ngIf="kareler.length < maxKareSayisi" class="add-container" (click)="ekleKare()">+</button>
              </div>
              <div *ngIf="errorCount > 0" class="error-message">
                Anket seçeneği olarak sadece emoji ekleyebilirsin!
              </div>
          </nz-descriptions-item>
          <nz-descriptions-item *ngIf="newSurvey.type == 5" nzSpan="4" nzTitle="Emoji Listesi">
            <div style="justify-content: space-between; flex-wrap: wrap; ">
              <div *ngFor="let kare of kareler" style="display: flex; align-items: center;margin-bottom: 10px;">
                <input [(ngModel)]="messageList[kare.id-1]"
                       nz-input
                       placeholder="Yanıt..."
                       style="height: 30px;">
                <div class="sil-btn-container">
                  <button *ngIf="kareler.length > minKareSayisi" class="sil-btn-2" (click)="silKare(kare.id)">
                    🗑️
                  </button>
                </div>
              </div>
              <button *ngIf="kareler.length < maxKareSayisi" class="add-container-2" (click)="ekleKare()">Yeni Seçenek Ekle +</button>
            </div>
          </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="Başlangıç Tarihi*">
          <nz-row>
            <nz-date-picker [(ngModel)]="newSurvey.startDate" [nzShowToday]="false" nzMode="date" (ngModelChange)="onDateChanged($event)" [nzFormat]="'dd.MM.yyyy'"></nz-date-picker>
            <nz-time-picker [nzNowText]="'Şimdi'" [nzOkText]="'Bitti'" [(ngModel)]="newSurvey.startDate" nzFormat="HH:mm"></nz-time-picker>
          </nz-row>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="Bitiş Tarihi*">
            <nz-row>
              <nz-date-picker [(ngModel)]="newSurvey.endDate" [nzShowToday]="false" nzMode="date" (ngModelChange)="onDateChanged($event)" [nzFormat]="'dd.MM.yyyy'"></nz-date-picker>
              <nz-time-picker [nzNowText]="'Şimdi'" [nzOkText]="'Bitti'" [(ngModel)]="newSurvey.endDate" nzFormat="HH:mm"></nz-time-picker>
            </nz-row>
          </nz-descriptions-item>
        <nz-descriptions-item *ngIf="role==='ADMIN'" nzSpan="5"
                              nzTitle="Firma">
          <nz-select [(ngModel)]="newSurvey.corporates" nzShowSearch nzPlaceHolder="Firma Ekle" [nzMaxTagCount]="2" nzMode="multiple" style="width: 100%;">
            <nz-option
              *ngFor="let option of corporateList"
              [nzLabel]="option.text"
              [nzValue]="option.id"
            ></nz-option>
          </nz-select>
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-spin>
  </ng-container>

</nz-modal>
<ng-template #attributeTagPlaceHolder let-selectedList> +{{ selectedList.length }}  </ng-template>

<nz-modal [nzWidth]="600" (nzOnCancel)="viewModalCancel()" (nzOnOk)="viewModalOk()"  nzOkText="Kaydet" [(nzVisible)]="viewModalVisible" >
    <ng-container *nzModalTitle>
      <span style="margin-right: 10px">{{selectedSurvey.question.substring(0,32)}} </span>
      <a (click)="startEdit()" *ngIf="!editing" style="font-weight: bold"><i
              class="icon" nz-icon
              nzType="edit"></i></a>
      <a (click)="cancelEdit()" *ngIf="editing" style="font-weight: bold"><i
              class="icon" nz-icon
              nzType="close"></i></a>
    </ng-container>
  
    <ng-container *nzModalContent>
      <ng-container *ngIf="!editing; else editTemplate">
  
        <nz-descriptions nzBordered nzSize="small">
          <nz-descriptions-item nzSpan="4" nzTitle="ID">{{selectedSurvey.id}}</nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" nzTitle="Soru">{{selectedSurvey.question}}</nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" nzTitle="Kampanya Türü">{{surveyType()}}</nz-descriptions-item>
          <nz-descriptions-item *ngIf="role==='ADMIN'" nzSpan="4" nzTitle="Firma">
            <nz-select [ngModel]="selectedSurvey.corporates"
                  [nzMaxTagCount]="2"
                  [nzMaxTagPlaceholder]="attributeTagPlaceHolder" nzMode="multiple" nzPlaceHolder="Üye Firmalar" style="width: 100%;">
                  <nz-option disabled=""
                    *ngFor="let option of corporateList"
                    [nzLabel]="option.text"
                    [nzValue]="option.id"
                    [nzDisabled]="true"
                  ></nz-option>
                </nz-select>
          </nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" nzTitle="Başlangıç Tarihi">{{selectedSurvey.startDate|date:'dd.MM.yyyy HH:mm:ss'}}</nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" nzTitle="Bitiş Tarihi">{{selectedSurvey.endDate|date:'dd.MM.yyyy HH:mm:ss'}}</nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" nzTitle="Durum">{{ selectedSurvey.status == 1 ? 'Beklemede' : selectedSurvey.status == 2 ? 'Yayında' : selectedSurvey.status == 3 ? 'Pasif' : 'Süresi Doldu'}}</nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" nzTitle="Oluşturulma Zamanı">{{selectedSurvey.createDate|date:'dd.MM.yyyy HH:mm:ss'}}</nz-descriptions-item>
        </nz-descriptions>
      </ng-container>
    </ng-container>
  </nz-modal>
  
  <ng-template #editTemplate>
    <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">
  
      <nz-descriptions nzBordered nzSize="small">
        <nz-descriptions-item nzSpan="4" nzTitle="ID">{{selectedSurvey.id}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Soru"><input [(ngModel)]="selectedSurvey.question" nz-input type="text" [disabled]="selectedSurveyStatus != 1"></nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Anket Tipi">
            <nz-select [nzPlaceHolder]="surveyType()!" [(ngModel)]="selectedSurvey.type" style="width: 40%;" (ngModelChange)="typeChange()" [nzDisabled]="selectedSurveyStatus != 1">
                <nz-option *ngFor="let c of typeAddList" [nzLabel]="c.text" [nzValue]="c.id"></nz-option>
              </nz-select>
          </nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" *ngIf="selectedSurvey.type == 2" nzTitle="Seçenek Listesi">
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <div *ngFor="let kare of kareler" style="margin: 10px; position: relative;">
                    <input [(ngModel)]="messageList[kare.id-1]"
                    (input)="onInput($event)"
                    (keydown)="onKeyDown($event, kare.id)"
                    maxlength="2"
                    style="height: 50px; width: 50px; text-align: center; font-size: 36px;"
                    [disabled]="selectedSurveyStatus != 1">
                    <div class="sil-btn-container">
                        <button *ngIf="kareler.length > minKareSayisi" class="sil-btn" (click)="silKare(kare.id)" [disabled]="selectedSurveyStatus != 1">
                            🗑️
                        </button>
                    </div>
                </div>
                <button *ngIf="kareler.length < maxKareSayisi" class="add-container" (click)="ekleKare()" [disabled]="selectedSurveyStatus != 1">+</button>
              </div>
              <div *ngIf="errorCount > 0" class="error-message">
                Anket seçeneği olarak sadece emoji ekleyebilirsin!
              </div>
          </nz-descriptions-item>
          <nz-descriptions-item *ngIf="selectedSurvey.type == 5" nzSpan="4" nzTitle="Emoji Listesi">
            <div style="justify-content: space-between; flex-wrap: wrap; ">
              <div *ngFor="let kare of kareler" style="display: flex; align-items: center;margin-bottom: 10px;">
                <input [(ngModel)]="messageList[kare.id-1]"
                       nz-input
                       placeholder="Yanıt..."
                       style="height: 30px;"
                       [disabled]="selectedSurveyStatus != 1">
                <div class="sil-btn-container">
                  <button *ngIf="kareler.length > minKareSayisi" class="sil-btn-2" (click)="silKare(kare.id)" [disabled]="selectedSurveyStatus != 1">
                    🗑️
                  </button>
                </div>
              </div>
              <button *ngIf="kareler.length < maxKareSayisi" class="add-container-2" (click)="ekleKare()" [disabled]="selectedSurveyStatus != 1">Yeni Seçenek Ekle +</button>
            </div>
          </nz-descriptions-item>
        <nz-descriptions-item *ngIf="role==='ADMIN'" nzSpan="4" nzTitle="Firma">
            <nz-select [(ngModel)]="selectedSurvey.corporates"
                  [nzMaxTagCount]="2"
                  [nzMaxTagPlaceholder]="attributeTagPlaceHolder" nzMode="multiple" nzPlaceHolder="Üye Firmalar" style="width: 100%;">
                  <nz-option
                    *ngFor="let option of corporateList"
                    [nzLabel]="option.text"
                    [nzValue]="option.id"
                  ></nz-option>
                </nz-select>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="Başlangıç Tarihi">
          <nz-row>
            <nz-date-picker [(ngModel)]="selectedSurvey.startDate" [nzShowToday]="false" nzMode="date" 
                            (ngModelChange)="onDateChanged($event)" [nzFormat]="'dd.MM.yyyy'" [nzDisabled]="selectedSurveyStatus != 1">
            </nz-date-picker>
            <nz-time-picker [nzNowText]="'Şimdi'" [nzOkText]="'Bitti'" [(ngModel)]="selectedSurvey.startDate" nzFormat="HH:mm" [nzDisabled]="selectedSurveyStatus != 1">
            </nz-time-picker>
          </nz-row>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="Bitiş Tarihi">
            <nz-row>
              <nz-date-picker [(ngModel)]="selectedSurvey.endDate" [nzShowToday]="false" nzMode="date" 
                              (ngModelChange)="onDateChanged($event)" [nzFormat]="'dd.MM.yyyy'">
              </nz-date-picker>
              <nz-time-picker [nzNowText]="'Şimdi'" [nzOkText]="'Bitti'" [(ngModel)]="selectedSurvey.endDate" nzFormat="HH:mm">
              </nz-time-picker>
            </nz-row>
          </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Durum">
          <nz-select [nzPlaceHolder]="selectedSurveyStatus == 4 ? 'Süresi Dolmuş' : selectedSurveyStatus == 2 ? 'Aktif' : ''" [nzDisabled]="selectedSurveyStatus == 4 ? true : false" [(ngModel)]="selectedSurvey.status" style="width: 40%;">
            <nz-option [nzLabel]="'Aktif'" [nzValue]="1"></nz-option>
            <nz-option [nzLabel]="'Pasif'" [nzValue]="3"></nz-option>
          </nz-select>
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-spin>
  </ng-template>