import {Pos} from "./pos";
import {StringValue} from "./string.value";

export class Supplier {
  name!: string;
  shortName!: string;
  category!: StringValue;
  contact!: string;
  contactPhone!: string;
  contactMail!: string;
  vkn!: string;
  id!: number;
  status!: boolean;
  posList!: Pos[];
  createDate!: number;
  logo!: any;
  waitingPeriod!: number;
  posDescription!: string;
}
