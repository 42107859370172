import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { lastValueFrom } from 'rxjs';
import { IntValue } from 'src/app/model/int.value';
import { SurveyRegisterRequest } from 'src/app/model/request/survey.register.request';
import { SurveyUpdateRequest } from 'src/app/model/request/survey.update.request';
import { Survey } from 'src/app/model/survey';
import { CorporateService } from 'src/app/service/corporate.service';
import { HelperService } from 'src/app/service/helper.service';
import { LocalStorageService } from 'src/app/service/local.storage.service';
import { SurveyService } from 'src/app/service/survey.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.less'],
})
export class SurveyComponent implements OnInit {

  tableDataLoading = false;
  isUpdating = false;
  surveyList!: Survey[];
  role !: string;
  corporateList!: IntValue[];
  addSurveyModalVisible = false;
  newSurvey!: SurveyRegisterRequest;
  viewModalVisible = false;
  selectedSurvey!: Survey;
  editing = false;
  typeList !: IntValue[]
  kareler: any[] = [{ id: 1 }, { id: 2 }];
  maxKareSayisi: number = 5;
  minKareSayisi: number = 2;
  errorCount : number = 0;
  messageList = [''];
  totalCount: number = 3;
  selectedSurveyStatus !: number;
  typeAddList !: IntValue[];
  corp!: number;

  constructor(private notification: NzNotificationService, private corporateService: CorporateService, private localStorage: LocalStorageService, private surveyService: SurveyService, private helperService: HelperService) {
    this.role = localStorage.get('role') ?? "";
    this.corp = parseInt(localStorage.get('corporate') ?? "1")
  }

  ngOnInit(): void {
    this.query();
    this.corporateService.list().subscribe(c => {
      this.corporateList = c.list;
    });
    this.surveyService.listTypes().subscribe(c => {
      this.typeList = c.list;
      this.typeAddList = c.list.filter(c => c.status != false);
    });
  }

  query() {
    this.tableDataLoading = true;
    console.log(this.role === 'DELEGATE')
    console.log(this.corp)
    this.surveyService.query(this.role === 'DELEGATE' ? this.corp : undefined).subscribe(c => {
      this.surveyList = c.surveys;
      this.tableDataLoading = false;
    });
  }

  showViewModal(data: Survey) {
    this.selectedSurvey = Object.assign({}, data);
    this.viewModalVisible = true;
    this.kareler = [{ id: 1 }, { id: 2 }];
    this.messageList = this.selectedSurvey.options.map(obj => Object.values(obj)[0]);
    this.updateKareLength(this.selectedSurvey.options.length);
    this.selectedSurveyStatus = this.selectedSurvey.status;
  }

  viewModalCancel() {
    this.viewModalVisible = false;
    this.editing = false;
  }

  viewModalOk() {
    var request = new SurveyUpdateRequest();
      request.id = this.selectedSurvey.id;
      request.question = this.selectedSurvey.question;
      request.type = this.selectedSurvey.type;
      request.startDate = this.helperService.dateTimezone(this.selectedSurvey.startDate);
      request.endDate = this.helperService.dateTimezone(this.selectedSurvey.endDate);
      request.corporates = this.selectedSurvey.corporates;
      request.status = this.selectedSurvey.status == 1 ? 0 : 3;
      request.optionList = this.messageList.filter(item => item.trim() !== '');
      
      if(request.question != null && request.type != null && request.startDate != null && request.endDate != null) {
        this.isUpdating = true;
        this.surveyService.update(request).subscribe(s => {
          if(s.isOk()) {
            this.query();
            this.viewModalVisible = false;
            this.editing = false;
          }
          this.isUpdating = false;
        });
      }else {
        this.notification.error('Hata', 'Zorunlu alanları eksiksiz doldurunuz.');
      }
  }

  surveyType() {
    return this.typeList.find(sv => sv.id === this.selectedSurvey.type)?.text;
  }

  surveyTypeWithData(data: Survey) {
    return this.typeList.find(sv => sv.id === data.type)?.text;
  }

  startEdit() {
    this.isUpdating = false;
    this.editing = true;
  }

  cancelEdit() {
    this.editing = false;
  }

  showAddSurveyModal() {
    this.addSurveyModalVisible = true;
    this.newSurvey = new SurveyRegisterRequest();
    this.newSurvey.optionList = [];
    this.newSurvey.type = 1;
  }

  addSurveyModalCancel() {
    this.addSurveyModalVisible = false;
  }

  addSurveyModalOk() {
    this.newSurvey.startDate = this.helperService.dateTimezone(this.newSurvey.startDate);
    this.newSurvey.endDate = this.helperService.dateTimezone(this.newSurvey.endDate);
    this.newSurvey.optionList = this.messageList.filter(item => item.trim() !== '');
    if(this.newSurvey.corporates == null && this.role === 'DELEGATE') {
      this.newSurvey.corporates = [this.corp];
    }
    if(this.newSurvey.question != null && this.newSurvey.type != null && this.newSurvey.startDate != null && this.newSurvey.endDate != null && this.errorCount <= 0 && (this.newSurvey.optionList.length == 0 || this.newSurvey.optionList.length >= 2)) {
      this.isUpdating = true;
      this.surveyService.register(this.newSurvey).subscribe(m => {
        if(m.isOk()) {
          this.addSurveyModalVisible = false;
          this.query()
        }
      });
      this.isUpdating = false;
    }else {
      this.notification.error('Hata', 'Zorunlu alanları eksiksiz doldurunuz.');
    }
  }

  onDateChanged(event: any) {
    event.setSeconds(0);
  }


  onInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    if (!value.match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g)) {
      if(value.length == 1) {
        this.errorCount++;
      }
    }
  }

  onKeyDown(event: KeyboardEvent, id: number): void {
    if (event.key === 'Backspace') {
      if (!this.messageList[id-1].match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g)) {
        this.errorCount--;
      }
      this.messageList[id-1] = '';
    }
  }

  ekleKare() {
    if (this.kareler.length < this.maxKareSayisi) {
      var yeniId = this.totalCount++;
      this.kareler.push({ id: yeniId });
    }
  }

  silKare(id: number) {
    if (this.kareler.length > this.minKareSayisi) {
      this.kareler = this.kareler.filter(k => k.id !== id);
      if (this.messageList[id-1].length != 0 && !this.messageList[id].match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g)) {
        this.errorCount--;;
      }
      this.messageList[id-1] = '';
    }
  }

  typeChange() {
    this.totalCount = 3;
    this.errorCount = 0;
    this.messageList = [''];
    this.kareler = [{ id: 1 }, { id: 2 }];
  }

  updateKareLength(leng: number) {
    this.errorCount = 0;
    if(leng > 2 && this.kareler.length != leng) {
      this.totalCount = 3;
      for (var i = 2; i < leng; i++) {
        this.ekleKare();
      }
    }
  }

  downloadAnswerData(data: Survey) {
    this.isUpdating = true;
    lastValueFrom(this.surveyService.getAnswerData(data.id)).then(v => {
        if (v.size != 0) {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(v);
            link.download = "anket-"+data.id+"_rapor.xlsx";
            link.click();
            this.isUpdating = false;
        }
    });
  }
}
