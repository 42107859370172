<nz-table #pushTable [nzData]="pushList" [nzLoading]="tableDataLoading" nzPaginationType="small" [nzPageSize]="10" nzBordered
            nzShowSizeChanger>
    <thead>
        <tr>
        <th nzWidth="8%">Id</th>
        <th nzWidth="15%">Başlık</th>
        <th nzWidth="15%">İçerik</th>
        <th nzWidth="16%" *ngIf="role==='ADMIN'">Firma</th>
        <th nzWidth="15%">Gönderim Tarihi</th>
        <th nzWidth="10%">Tür</th>
        <th nzWidth="10%">Durum</th>
        <th nzWidth="11%">İşlemler</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of pushTable.data">
        <ng-container>
            <td>{{data.id}}</td>
            <td>{{data.title}}</td>
            <td>{{data.content}}</td>
            <td *ngIf="role==='ADMIN'">
                <nz-select [ngModel]="data.corporates"
                  [nzMaxTagCount]="2"
                  [nzMaxTagPlaceholder]="attributeTagPlaceHolder" nzMode="multiple" nzPlaceHolder="Üye Firmalar" style="width: 100%;">
                  <nz-option disabled=""
                    *ngFor="let option of corporateList"
                    [nzLabel]="option.text"
                    [nzValue]="option.id"
                    [nzDisabled]="true"
                  ></nz-option>
                </nz-select>
              </td>
            <td>
                <div>{{data.shareDate|date:'dd.MM.yyyy HH:mm'}}</div>
            </td>
            <td text-align="center" [ngSwitch]="data.type">
                <nz-tag  *ngSwitchCase="1">Transaction</nz-tag>
                <nz-tag *ngSwitchCase="2" >Kampanya</nz-tag>
                <nz-tag *ngSwitchCase="3" >Duyuru</nz-tag>
                <nz-tag *ngSwitchCase="4">Manuel</nz-tag>
            </td>
            <td text-align="center" [ngSwitch]="data.status">
                <nz-tag  *ngSwitchCase="1" [nzColor]="'#ffb475'">Beklemede</nz-tag>
                <nz-tag *ngSwitchCase="2" [nzColor]="'#87d068'">Gönderildi</nz-tag>
                <nz-tag *ngSwitchCase="3" [nzColor]="'#ff2432'">İptal Edildi</nz-tag>
            </td>
            <td>
              <button (click)="showViewModal(data)" nz-button nzShape= "circle" nzType= "primary" style="font-weight: bold; margin-left: 10px; margin-right: 10px">
                  <i class="icon" nz-icon nzType="eye"></i>
              </button>
            </td>
        </ng-container>
        </tr>
    </tbody>
  </nz-table>
  
<button (click)="showAddPushModal()" nz-button nzShape="round" nzType="primary" style="margin-top: 10px">Push Ekle<i nz-icon nzType="plus"></i></button>

<nz-modal (nzOnCancel)="addPushModalCancel()" (nzOnOk)="addPushModalOk()"
          [(nzVisible)]="addPushModalVisible" nzOkText="Kaydet">

  <ng-container *nzModalTitle>
    <span style="margin-right: 10px; font-weight: bold">Push Ekle</span>
  </ng-container>

  <ng-container *nzModalContent>
  <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">
      <nz-descriptions nzBordered nzSize="small">
        <nz-descriptions-item nzSpan="5" nzTitle="Başlık*">
          <input [(ngModel)]="newPush.title" nz-input type="text">
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="İçerik*" color="red">
            <textarea [(ngModel)]="newPush.content" nz-input rows="4"></textarea>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="Paylaşım Tarihi*">
          <nz-row>
            <nz-date-picker [(ngModel)]="newPush.shareDate" [nzShowToday]="false" nzMode="date" (ngModelChange)="onDateChanged($event)" [nzFormat]="'dd.MM.yyyy'"></nz-date-picker>
            <nz-time-picker [nzDisabledHours]="disabledHours" [nzDisabledMinutes]="disabledMinutes" [nzMinuteStep]="30" [nzNowText]="'Şimdi'" [nzOkText]="'Bitti'" [(ngModel)]="newPush.shareDate" nzFormat="HH:mm"></nz-time-picker>
          </nz-row>
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="role==='ADMIN'" nzSpan="5"
                              nzTitle="Firma">
          <nz-select [(ngModel)]="newPush.corporates" nzShowSearch nzPlaceHolder="Firma Ekle" [nzMaxTagCount]="2" nzMode="multiple" style="width: 100%;">
            <nz-option
              *ngFor="let option of corporateList"
              [nzLabel]="option.text"
              [nzValue]="option.id"
            ></nz-option>
          </nz-select>
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-spin>
  </ng-container>

</nz-modal>
<ng-template #attributeTagPlaceHolder let-selectedList> +{{ selectedList.length }}  </ng-template>

<nz-modal [nzWidth]="600" (nzOnCancel)="viewModalCancel()" (nzOnOk)="viewModalOk()"  nzOkText="Kaydet" [(nzVisible)]="viewModalVisible" >
    <ng-container *nzModalTitle>
      <span style="margin-right: 10px">{{selectedPush.title.substring(0,32)}} </span>
      <a (click)="startEdit()" *ngIf="!editing" style="font-weight: bold"><i
              class="icon" nz-icon
              nzType="edit"></i></a>
      <a (click)="cancelEdit()" *ngIf="editing" style="font-weight: bold"><i
              class="icon" nz-icon
              nzType="close"></i></a>
    </ng-container>
  
    <ng-container *nzModalContent>
      <ng-container *ngIf="!editing; else editTemplate">
  
        <nz-descriptions nzBordered nzSize="small">
          <nz-descriptions-item nzSpan="4" nzTitle="ID">{{selectedPush.id}}</nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" nzTitle="Başlık">{{selectedPush.title}}</nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" nzTitle="İçerik">{{selectedPush.content}}</nz-descriptions-item>
          <nz-descriptions-item *ngIf="role==='ADMIN'" nzSpan="4" nzTitle="Firma">
            <nz-select [ngModel]="selectedPush.corporates"
                  [nzMaxTagCount]="2"
                  [nzMaxTagPlaceholder]="attributeTagPlaceHolder" nzMode="multiple" nzPlaceHolder="Üye Firmalar" style="width: 100%;">
                  <nz-option disabled=""
                    *ngFor="let option of corporateList"
                    [nzLabel]="option.text"
                    [nzValue]="option.id"
                    [nzDisabled]="true"
                  ></nz-option>
                </nz-select>
          </nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" nzTitle="Gönderilecek Tarih">{{selectedPush.shareDate|date:'dd.MM.yyyy HH:mm:ss'}}</nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" nzTitle="Tür">
            {{selectedPush.type == 2 ? 'Kampanya' : selectedPush.type == 3 ? 'Duyuru' : selectedPush.type == 4 ? 'Manuel' : ''}}
          </nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" nzTitle="Durum">
            {{selectedPush.status == 1 ? 'Beklemede' : selectedPush.status == 2 ? 'Gönderildi' : selectedPush.status == 3 ? 'İptal Edildi' : ''}}
          </nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" nzTitle="Oluşturulma Zamanı">{{selectedPush.createDate|date:'dd.MM.yyyy HH:mm:ss'}}</nz-descriptions-item>
        </nz-descriptions>
      </ng-container>
    </ng-container>
  </nz-modal>
  
  <ng-template #editTemplate>
    <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">
  
      <nz-descriptions nzBordered nzSize="small">
        <nz-descriptions-item nzSpan="4" nzTitle="ID">{{selectedPush.id}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Başlık">
            <input [disabled]="selectedPush.status == 2 ? true : false" [(ngModel)]="selectedPush.title" nz-input type="text">
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="İçerik">
            <input [disabled]="selectedPush.status == 2 ? true : false" [(ngModel)]="selectedPush.content" nz-input type="text">
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="role==='ADMIN'" nzSpan="4" nzTitle="Firma">
            <nz-select [(ngModel)]="selectedPush.corporates"
                  [nzMaxTagCount]="2"
                  [nzMaxTagPlaceholder]="attributeTagPlaceHolder" nzMode="multiple" nzPlaceHolder="Üye Firmalar" style="width: 100%;">
                  <nz-option
                    *ngFor="let option of corporateList"
                    [nzLabel]="option.text"
                    [nzValue]="option.id"
                    [nzDisabled]="selectedPush.status == 2 ? true : false"
                  ></nz-option>
                </nz-select>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="Paylaşılacak Tarih">
          <nz-row>
            <nz-date-picker [disabled]="selectedPush.status == 2 ? true : false" [(ngModel)]="selectedPush.shareDate" [nzShowToday]="false" nzMode="date" 
                            (ngModelChange)="onDateChanged($event)" [nzFormat]="'dd.MM.yyyy'">
            </nz-date-picker>
            <nz-time-picker [disabled]="selectedPush.status == 2 ? true : false" [nzDisabledHours]="disabledHours" [nzDisabledMinutes]="disabledMinutes" [nzMinuteStep]="30" 
                            [nzNowText]="'Şimdi'" [nzOkText]="'Bitti'" [(ngModel)]="selectedPush.shareDate" nzFormat="HH:mm">
            </nz-time-picker>
          </nz-row>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Durum">
          <nz-select [nzDisabled]="selectedPush.status == 2 ? true : false" [(ngModel)]="selectedPush.status" style="width: 40%;">
            <nz-option [nzLabel]="'Beklemede'" [nzValue]="1"></nz-option>
            <nz-option [nzLabel]="'Gönderildi'" [nzValue]="2" [nzDisabled]="true"></nz-option>
            <nz-option [nzLabel]="'İptal Edildi'" [nzValue]="3"></nz-option>
          </nz-select>
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-spin>
  </ng-template>