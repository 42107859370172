import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UrlConfig} from "../config/url";
import {map} from "rxjs/operators";
import {CorporateQueryResponse} from "../model/response/corporate.query.response";
import {CorporateSaveRequest} from "../model/request/corporate.save.request";
import {CorporateSaveResponse} from "../model/response/corporate.save.response";
import {CorporateListResponse} from "../model/response/corporate.list.response";
import { CorporateAddRegistrationResponse } from "../model/response/corporate.add.registration.response";
import { CorporateCampaignsResponse } from "../model/response/corporate.campaigns.response";

@Injectable()
export class CorporateService {
  constructor(private http: HttpClient) {
  }

  query() {
    return this.http.get(UrlConfig.corporateQuery).pipe(
      map(m => {
        return new CorporateQueryResponse(m)
      })
    );
  }

  list() {
    return this.http.get(UrlConfig.corporateList).pipe(
      map(m => {
        return new CorporateListResponse(m)
      })
    );
  }

  getLogo(id:number) {
    return this.http.get(UrlConfig.corporateLogo+id,
      { responseType: 'blob' });
  }

  uploadLogo(logo:File,id:number){
    const formData=new FormData();
    formData.append("file",logo);
    formData.append("id",id.toString());
    return this.http.post(UrlConfig.corporateUploadLogo,formData);
  }

  save(request: CorporateSaveRequest) {
    return this.http.post(UrlConfig.corporateRegister, request).pipe(
      map(c=>new CorporateSaveResponse(c))
    );
  }

  update(request: CorporateSaveRequest) {
    return this.http.post(UrlConfig.corporateUpdate, request).pipe(
      map(c=>new CorporateSaveResponse(c))
    );
  }

  addRegistration(file: File, corporateId: number, registrationStyle: string, isDefault: boolean){
    const formData = new FormData();
    formData.append("file", file);
    formData.append("corporateId", corporateId.toPrecision());
    if(!isDefault){
      formData.append("style", registrationStyle.toString());
    }else{
      formData.append("style", "");
    }
    return this.http.post(UrlConfig.corporateAddRegistration, formData).pipe(
      map(c=>new CorporateAddRegistrationResponse(c))
    );
  }

  downloadRegistrationFile(corporateId: number){
    const formData = new FormData();
    formData.append("corporateId", corporateId.toPrecision());
    return this.http.post(UrlConfig.corporateDownloadCouponFile, formData,
      { responseType: 'blob' });
  }

  corporateCampaigns(id:number){
    return this.http.get(UrlConfig.corporateCampaignList+id).pipe(map(m => new CorporateCampaignsResponse(m)));
  }
  
  getCorporateFile() {
    return this.http.get(UrlConfig.corporateFile, { responseType: 'blob' } );
  }
}
