import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UrlConfig} from "../config/url";
import {map} from "rxjs/operators";
import {UserInfoResponse} from "../model/response/user.info.response";
import {UserQueryResponse} from "../model/response/user.query.response";
import {UserUpdateResponse} from "../model/response/user.update.response";
import {UserUpdateRequest} from "../model/request/user.update.request";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {
  }

  userInfo() {
    return this.http.get(UrlConfig.info).pipe(map(data => new UserInfoResponse(data)));
  }

  query() {
    return this.http.post(UrlConfig.query, {}).pipe(map(data => {
      return new UserQueryResponse(data);
    }));
  }

  update(request: UserUpdateRequest) {
    return this.http.post(UrlConfig.update, request).pipe(map(data => {
      return new UserUpdateResponse(data);
    }));
  }

  getUserFile() {
    return this.http.get(UrlConfig.userFile, { responseType: 'blob' } );
  }

  getTransactionFile() {
    return this.http.get(UrlConfig.transactionFile, { responseType: 'blob' } );
  }
}
