import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent} from "./login/login.component";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzInputModule} from "ng-zorro-antd/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NzButtonModule} from "ng-zorro-antd/button";
import {AuthRoutingModule} from "./auth-routing.module";
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";
import {NzSpaceModule} from "ng-zorro-antd/space";
import {NgxMaskModule} from "ngx-mask";
import {NzSpinModule} from "ng-zorro-antd/spin";
import { OtpComponent } from './otp/otp.component';



@NgModule({
  declarations: [LoginComponent, OtpComponent],
    imports: [
        CommonModule,
        NzFormModule,
        NzInputModule,
        ReactiveFormsModule,
        AuthRoutingModule,
        NzButtonModule,
        NzCheckboxModule,
        NzSpaceModule,
        NgxMaskModule,
        NzSpinModule,
        FormsModule
    ],
  exports:[LoginComponent, OtpComponent]
})
export class AuthModule { }
