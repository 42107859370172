import {Component, OnInit} from '@angular/core';
import {Supplier} from "../../model/supplier";
import {SupplierService} from "../../service/supplier.service";
import {StringValue} from "../../model/string.value";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {lastValueFrom, timer} from "rxjs";
import { Pos } from 'src/app/model/pos';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.less']
})
export class SupplierComponent implements OnInit {
  dataLoading = false;
  supplierList!: Supplier[];
  selectedSupplier!: Supplier;
  tempSelectedSupplier!: Supplier;
  newSupplier!: Supplier;
  supplierRegisterModalVisible = false;
  supplierModalVisible = false;
  supplierIsEditing = false;
  selectedLogo!: File;
  categories!: StringValue[];
  isUpdating = false;

  categoryFilterList: { text: string, value: string }[] = [];
  statusFilterList: { text: string, value: boolean }[] = [];

  flashTableClass = "";
  updateId=-1;
  posDetailModalVisible=false;
  addPosModalVisible = false;
  posFile?: File;

  constructor(private supplierService: SupplierService, private notification: NzNotificationService) {
  }

  ngOnInit(): void {
    this.query();

  }

  query() {
    this.dataLoading = true;
    this.supplierService.query().subscribe(d => {
      this.supplierList = d.suppliers;
      this.dataLoading = false;
      this.categoryFilterList = d.suppliers.map(m => {
        return {text: m.category.text, value: m.category.value};
      }).filter((value, index, self) => self.map(ss => ss.value).indexOf(value.value) === index);
      this.statusFilterList = d.suppliers.map(m => {
        return {text: m.status ? 'Aktif' : 'Pasif', value: m.status};
      }).filter((value, index, self) => self.map(ss => ss.value).indexOf(value.value) === index);
      this.flashRow();
    });
  }

  startEdit() {
    this.categoryList();
    this.supplierIsEditing = true;

  }

  cancelEdit() {
    this.supplierIsEditing = false;
  }

  showModal(supplier: Supplier) {
    this.selectedSupplier = supplier;
    this.tempSelectedSupplier = Object.assign({}, supplier);
    lastValueFrom(this.supplierService.getLogo(supplier.id)).then(v => {
      if (v.size != 0) {
        const reader = new FileReader();
        reader.onload = (e) => this.selectedSupplier.logo = e.target!.result;
        reader.readAsDataURL(v);
      }
      this.supplierModalVisible = true;
    });

  }

  modalOk() {
    this.isUpdating=true;
    this.supplierService.update(this.selectedSupplier).subscribe(s => {
      if (s.isOk()) {
        this.updateId=s.id;
        if (this.selectedLogo != null && this.selectedLogo.size > 10) {
          this.supplierService.uploadLogo(this.selectedLogo, s.id).subscribe(() => {
            this.supplierModalVisible = false;

            this.query();

          });
        } else {
          this.supplierModalVisible = false;
          this.query();
        }
      }else {
        Object.assign(this.selectedSupplier, this.tempSelectedSupplier);
      }
      this.supplierIsEditing = false;
      this.isUpdating=false;
    });
  }

  modalCancel() {
    this.supplierModalVisible = false;
    this.supplierIsEditing = false;
    Object.assign(this.selectedSupplier, this.tempSelectedSupplier);
  }

  showRegisterModal() {
    this.supplierRegisterModalVisible = true;
    this.categoryList();
    this.newSupplier = new Supplier();
    this.newSupplier.category = new StringValue();
    this.newSupplier.status = true;
  }

  registerModalOk() {
    this.supplierService.register(this.newSupplier).subscribe(s => {
      if (s.isOk()) {
        this.updateId=s.id;
        if (this.selectedLogo != null && this.selectedLogo.size > 10) {
          this.supplierService.uploadLogo(this.selectedLogo, s.id).subscribe(() => {
            this.supplierRegisterModalVisible = false;
            this.query();
          });
        } else {
          this.supplierRegisterModalVisible = false;
          this.query();
        }
      }

    });
  }

  registerModalCancel() {
    this.supplierRegisterModalVisible = false;
  }

  onFileChanged(event: any) {
    this.selectedLogo = event.target.files[0];

  }

  categoryList() {
    return this.supplierService.categoryList().subscribe(s => {
      this.categories = s.list;
    });
  }

  categoryFilterFn(list: string[], item: Supplier) {
    return list.some(name => item.category.value.indexOf(name) !== -1)
  }

  statusFilterFn(list: boolean[], item: Supplier) {
    return list.some(name => item.status == name);
  }

  flashRow() {
    var count = 5;
    var countDown=timer(0, 500).subscribe(() => {
      if (count % 2 == 0) {
        this.flashTableClass = 'green-background';
      } else {
        this.flashTableClass = '';
      }
      --count;
      if(count==0){
        countDown.unsubscribe();
        this.updateId=-1;
      }
    });
  }

  showAddPosModal(supplier:Supplier) {
    this.posDetailModalVisible=false;
    this.posFile = undefined;
    this.selectedSupplier=supplier;
    this.addPosModalVisible=true;
  }

  addPosModalOk() {
    this.isUpdating=true;
    if(!this.posDetailModalVisible){
      if(this.posFile != undefined){
        this.supplierService.addPos(this.posFile, this.selectedSupplier.id).subscribe(s => {
          if(s.isOk()){
            this.addPosModalVisible=false;
            this.notification.success('', 'Pos tanımlaması yapıldı.');
          }
          this.isUpdating=false;
        });
      }else{
        this.isUpdating=false;
        this.notification.error("Hata", "Tüm bilgileri eksiksiz girin.")
      }
    }
  }

  addPosModalCancel() {
    this.addPosModalVisible=false;
  }

  getPosFile(event:any) {
    let file = event.target.files[0];
    this.posFile = file;
  }
  
  showPosDetailModal() {
    this.posDetailModalVisible=true;
  }

  downloadPosFile() {
    this.isUpdating = true;
    lastValueFrom(this.supplierService.downloadRegistrationFile(this.selectedSupplier.id)).then(v=>{
      if(v.size != 0){
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(v);
          link.download = this.selectedSupplier.shortName  + "-pos_detay.xlsx";
          link.click();
          this.addPosModalVisible = false;
          this.isUpdating = false;
      }else{
          this.notification.error("Hata", "Tanımlı veri bulunamadı.");
          this.isUpdating = false;
      }
    });
  }

  cancelPosDetailModal() {
    this.posDetailModalVisible=false;
  }
}
