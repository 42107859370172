<nz-table #editRowTable [nzData]="supplierList" [nzLoading]="dataLoading" [nzPageSize]="10" nzBordered
          nzShowSizeChanger>
  <thead>
  <tr>
    <th>Firma Adı
    </th>
    <th [nzFilterFn]="categoryFilterFn" [nzFilterMultiple]="true" [nzFilters]="categoryFilterList">Kategori</th>
    <th>Yetkili</th>
    <th>Yetkili Telefon</th>
    <th>Yetkili Mail</th>
    <th [nzFilterFn]="statusFilterFn" [nzFilters]="statusFilterList" nzWidth="5%">Durum</th>
    <th nzWidth="200px">İşlemler</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of editRowTable.data" [class]="data.id==updateId?flashTableClass:''">
    <ng-container>
      <td>

        {{ data.name }}
      <td>
        {{data.category.text}}
      </td>
      <td>
        {{data.contact}}
      </td>
      <td>
        <div>{{ data.contactPhone|mask: '(000) 000 00 00' }}</div>
      </td>
      <td>{{ data.contactMail }}</td>
      <td>
        <nz-tag  [nzColor]="data.status?'#87d068':'#f50'">{{ data.status ? 'Aktif' : 'Pasif' }}</nz-tag>
      </td>

      <td>
        <button (click)="showModal(data)" nz-button nzShape="circle" nzType="primary"
                style="font-weight: bold; margin-left: 10px; margin-right: 10px">
          <i class="icon"
             nz-icon
             nzType="eye"></i>
        </button>
        <button (click)="showAddPosModal(data)" nz-button nzShape="round" nzType="dashed">Pos Tanımı</button>
      </td>

    </ng-container>
  </tr>
  </tbody>
</nz-table>
<div style="float: left">
  <button (click)="showRegisterModal()" nz-button nzShape="round"  nzType="primary">Tedarikçi ekle <i
    nz-icon nzType="plus"> </i>
  </button>
</div>

<nz-modal (nzOnCancel)="registerModalCancel()" (nzOnOk)="registerModalOk()" [(nzVisible)]="supplierRegisterModalVisible"
          nzOkText="Kaydet">
  <ng-container *nzModalTitle>
    <span style="margin-right: 10px">Tedarikçi Firma Ekle</span>
  </ng-container>
  <ng-container *nzModalContent>
    <ng-container>

      <nz-descriptions nzBordered nzSize="small">
        <nz-descriptions-item nzSpan="4" nzTitle="Logo"><input (change)="onFileChanged($event)"
                                                               accept="image/x-png,image/jpeg"
                                                               type="file">
        </nz-descriptions-item>

        <nz-descriptions-item nzSpan="4" nzTitle="Firma Adı"><input [(ngModel)]="newSupplier.name" nz-input
                                                                    type="text"></nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Firma Kısa Adı"><input [(ngModel)]="newSupplier.shortName" nz-input
                                                                         type="text"></nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Vergi Kimlik No."><input [(ngModel)]="newSupplier.vkn" nz-input
                                                                           type="text"></nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Yetkili"><input [(ngModel)]="newSupplier.contact" nz-input
                                                                  type="text"></nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                              nzTitle=" Yetkili Telefon"><input [(ngModel)]="newSupplier.contactPhone"
                                                                mask="(000) 000 00 00"
                                                                nz-input
                                                                type="tel"></nz-descriptions-item>

        <nz-descriptions-item nzSpan="4"
                              nzTitle="Yetkili Mail"><input [(ngModel)]="newSupplier.contactMail" nz-input
                                                            type="text"></nz-descriptions-item>

        <nz-descriptions-item nzSpan="4"
                              nzTitle="Kategori">
          <nz-select nzAllowClear nzPlaceHolder="Kategori Seç" nzShowSearch [(ngModel)]="newSupplier.category.value" style="width: 150px">
            <nz-option *ngFor="let c of categories" [nzLabel]="c.text" [nzValue]="c.value"></nz-option>
          </nz-select>

        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Cashback Bekleme Süresi"><input [(ngModel)]="newSupplier.waitingPeriod" nz-input type="number"></nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Pos Açıklaması"><input [(ngModel)]="newSupplier.posDescription" nz-input type="text"></nz-descriptions-item>

        <nz-descriptions-item nzSpan="4" nzTitle="Durum">
          <nz-select [(ngModel)]="newSupplier.status">
            <nz-option [nzLabel]="'Aktif'" [nzValue]="true"></nz-option>
            <nz-option [nzLabel]="'Pasif'" [nzValue]="false"></nz-option>
          </nz-select>
        </nz-descriptions-item>

      </nz-descriptions>
    </ng-container>

  </ng-container>
</nz-modal>


<nz-modal (nzOnCancel)="modalCancel()" (nzOnOk)="modalOk()" [(nzVisible)]="supplierModalVisible" nzOkText="Kaydet">
  <ng-container *nzModalTitle>
    <span style="margin-right: 10px">Tedarikçi Firma Bilgileri</span>
    <a (click)="startEdit()" *ngIf="!supplierIsEditing" style="font-weight: bold"><i
      class="icon" nz-icon
      nzType="edit"></i></a>
    <a (click)="cancelEdit()" *ngIf="supplierIsEditing" style="font-weight: bold"><i
      class="icon" nz-icon
      nzType="close"></i></a>
  </ng-container>

  <ng-container *nzModalContent>
    <ng-container *ngIf="!supplierIsEditing; else editTemplate">

      <nz-descriptions nzBordered nzSize="small">
        <nz-descriptions-item nzSpan="4" nzTitle="Logo"><img [src]="selectedSupplier.logo"
                                                             alt="{{selectedSupplier.shortName}} logo"
                                                             height="64"></nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Firma ID">{{selectedSupplier.id}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Firma Adı">{{selectedSupplier.name}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Firma Kısa Adı">{{selectedSupplier.shortName}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Kategori">{{selectedSupplier.category.text}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Cashback Bekleme Süresi">{{selectedSupplier.waitingPeriod}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Pos Açıklaması">{{selectedSupplier.posDescription}}</nz-descriptions-item>

        <nz-descriptions-item nzSpan="4" nzTitle="Vergi Kimlik No.">{{selectedSupplier.vkn}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Yetkili">{{selectedSupplier.contact}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                              nzTitle=" Yetkili Telefon">{{selectedSupplier.contactPhone|mask: '(000) 000 00 00'}}</nz-descriptions-item>

        <nz-descriptions-item nzSpan="4"
                              nzTitle="Yetkili Mail">{{selectedSupplier.contactMail}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                              nzTitle="Durum">{{ selectedSupplier.status ? 'Aktif' : 'Pasif' }}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                              nzTitle="Oluşturulma Zamanı">{{selectedSupplier.createDate|date:'dd.MM.yyyy HH:mm'}}</nz-descriptions-item>

      </nz-descriptions>
    </ng-container>

  </ng-container>

</nz-modal>

<ng-template #editTemplate>
  <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">

    <nz-descriptions nzBordered nzSize="small">
      <nz-descriptions-item nzSpan="4" nzTitle="Logo"><input (change)="onFileChanged($event)"
                                                             accept="image/x-png,image/jpeg"
                                                             type="file">
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Firma ID">{{selectedSupplier.id}}</nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Firma Adı"><input [(ngModel)]="selectedSupplier.name" nz-input
                                                                  type="text"></nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Firma Kısa Adı"><input [(ngModel)]="selectedSupplier.shortName"
                                                                       nz-input
                                                                       type="text"></nz-descriptions-item>

      <nz-descriptions-item nzSpan="4"
                            nzTitle="Kategori">
        <nz-select nzAllowClear nzPlaceHolder="Kategori Seç" nzShowSearch [(ngModel)]="selectedSupplier.category.value" style="width: 150px">
          <nz-option *ngFor="let c of categories" [nzLabel]="c.text" [nzValue]="c.value"></nz-option>
        </nz-select>

      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Cashback Bekleme Süresi"><input [(ngModel)]="selectedSupplier.waitingPeriod" nz-input type="number"></nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Pos Açıklaması"><input [(ngModel)]="selectedSupplier.posDescription" nz-input type="text"></nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Vergi Kimlik No."><input [(ngModel)]="selectedSupplier.vkn" nz-input
                                                                         type="text"></nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Yetkili"><input [(ngModel)]="selectedSupplier.contact" nz-input
                                                                type="text"></nz-descriptions-item>
      <nz-descriptions-item nzSpan="4"
                            nzTitle=" Yetkili Telefon"><input [(ngModel)]="selectedSupplier.contactPhone"
                                                              mask="(000) 000 00 00"
                                                              nz-input
                                                              type="tel"></nz-descriptions-item>

      <nz-descriptions-item nzSpan="4"
                            nzTitle="Yetkili Mail"><input [(ngModel)]="selectedSupplier.contactMail" nz-input
                                                          type="text"></nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Durum">
        <nz-select [(ngModel)]="selectedSupplier.status">
          <nz-option [nzLabel]="'Aktif'" [nzValue]="true"></nz-option>
          <nz-option [nzLabel]="'Pasif'" [nzValue]="false"></nz-option>
        </nz-select>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="4"
                            nzTitle="Oluşturulma Zamanı">{{selectedSupplier.createDate|date:'dd.MM.yyyy HH:mm'}}</nz-descriptions-item>


    </nz-descriptions>


  </nz-spin>
</ng-template>

<nz-modal (nzOnCancel)="addPosModalCancel()" (nzOnOk)="addPosModalOk()"
          [(nzVisible)]="addPosModalVisible" nzOkText="Kaydet">

  <ng-container *nzModalTitle>
    <span style="margin-right: 10px; font-weight: bold">
      {{selectedSupplier.shortName}} Pos {{!posDetailModalVisible ?"Ekle" : "Tanımları"}}</span>
    <a (click)="showPosDetailModal()" *ngIf="!posDetailModalVisible" style="font-weight: bold"><i
            class="icon" nz-icon
            nzType="file-search"></i></a>
    <a (click)="cancelPosDetailModal()" *ngIf="posDetailModalVisible" style="font-weight: bold"><i
            class="icon" nz-icon
            nzType="close"></i></a>
  </ng-container>

  <ng-container *nzModalContent>
    <ng-container *ngIf="!posDetailModalVisible; else posDetailTemplate">
    <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">
      <nz-descriptions nzBordered nzSize="small">
        
        <nz-descriptions-item nzSpan="5" nzTitle="Pos Tanım Listesi">
          <input type="file" (change)="getPosFile($event)" class="file"
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
          <td>
            <small>( 1/Merchant ID, 2/Açıklama, 3/Pos Tipi [sanal/fiziksel] )</small>
          </td>
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-spin>
    </ng-container>
  </ng-container>
</nz-modal>

<ng-template #posDetailTemplate>
  <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">
    <nz-table #detailTable
              [nzFrontPagination]="false"
              [nzShowPagination]="false"
              nzBordered>

      <nz-descriptions nzBordered nzSize="small">

        <nz-descriptions-item nzSpan="5" nzTitle="Pos Tanımları Listesi">
          <button (click)="downloadPosFile()">Download File</button>
        </nz-descriptions-item>

      </nz-descriptions>
    </nz-table>
  </nz-spin>
</ng-template>
