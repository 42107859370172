import {Supplier} from "../supplier";
import {Pos} from "../pos";
import {StringValue} from "../string.value";

export class SupplierUpdateRequest {
  id!:number;
  name!: string;
  shortName!: string;
  category!: string;
  contact!: string;
  contactPhone!: string;
  contactMail!: string;
  vkn!: string;
  status!: boolean;
  posList!: Pos[];
  waitingPeriod!: number;
  posDescription!: string;

  constructor(supplier: Supplier) {
    this.id=supplier.id;
    this.name = supplier.name;
    this.shortName = supplier.shortName;
    this.vkn = supplier.vkn;
    this.contact = supplier.contact;
    this.contactPhone = supplier.contactPhone;
    this.contactMail = supplier.contactMail;
    this.status = supplier.status;
    this.posList = supplier.posList;
    this.category=supplier.category.value;
    this.waitingPeriod = supplier.waitingPeriod;
    this.posDescription = supplier.posDescription;
  }
}
