import {RouterModule, Routes} from "@angular/router";
import {PagesComponent} from "./pages.component";
import {UsersComponent} from "./users/users.component";
import {NgModule} from "@angular/core";
import {AuthorizeGuard} from "../service/authorize.guard.service";
import {CorporateComponent} from "./corporate/corporate.component";
import {CampaignComponent} from "./campaign/campaign.component";
import {SupplierComponent} from "./supplier/supplier.component";
import {TransactionComponent} from "./transaction/transaction.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import { CashbackComponent } from "./cashback/cashback.component";
import { EmailLogComponent } from "./email-log/email-log.component";
import { AnnouncementComponent } from "./announcement/announcement.component";
import { PushComponent } from "./push/push.component";
import { SurveyComponent } from "./survey/survey.component";

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [AuthorizeGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'corporate',
        component: CorporateComponent
      }, {
        path: 'campaign',
        component: CampaignComponent
      }, {
        path: 'supplier',
        component: SupplierComponent
      }, {
        path: 'cashback',
        component: CashbackComponent
      }, {
        path: 'transaction',
        component: TransactionComponent
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'email-log',
        component: EmailLogComponent
      },
      {
        path: 'announcement',
        component: AnnouncementComponent
      },
      {
        path: 'push',
        component: PushComponent
      },
      {
        path: 'survey',
        component: SurveyComponent
      }
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class PagesRoutingModule {
}
