import {Serializable} from "./serializable";
import {StringValue} from "./string.value";

export class User{
  id!: number;
  username!: string;
  firstName!: string;
  lastName!: string;
  tckn!: number;
  birthDate!: string;
  birthPlace!: string;
  createDate!:number;
  phone!: number;
  newPhone!: number;
  email!: string;
  employeeNo!:string;
  status!: number
  userRole!: StringValue;
  corporate!: string;
  corporateId!: number;
  constructor(user:any) {
    Object.assign(this,user)
  }
}
