import {User} from "../user";

export class UserUpdateRequest {
  phone!: number;
  newPhone!: number;
  email!: string;
  name!: string;
  surname!: string;
  birthDate!: Date;
  birthPlace!: string;
  userStatus!: number;
  employeeNo!:string;
  corporateId!:number;
  constructor(user: User) {
    this.phone = user.phone;
    this.newPhone=user.newPhone;
    this.email = user.email;
    this.name = user.firstName;
    this.surname = user.lastName;
    this.userStatus =user.status;
    this.employeeNo=user.employeeNo;
    this.birthDate=new Date(user.birthDate);
    this.birthPlace=user.birthPlace;
    this.corporateId=user.corporateId;
  }
}
