import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PushRegisterRequest } from "../model/request/push.register.request";
import { UrlConfig } from "../config/url";
import { map } from "rxjs";
import { PushRegisterResponse } from "../model/response/push.register.response";
import { PushQueryResponse } from "../model/response/push.query.response";
import { PushUpdateRequest } from "../model/request/push.update.request";
import { PushUpdateResponse } from "../model/response/push.update.response";

@Injectable()
export class PushService {
    constructor(private http: HttpClient) {}

    register(request: PushRegisterRequest) {
        return this.http.post(UrlConfig.pushRegister, request).pipe(map(m => {
            return new PushRegisterResponse(m);
        }));
    }

    query() {
        return this.http.get(UrlConfig.pushQuery).pipe(map(m => {
            return new PushQueryResponse(m);
        }));
    }

    update(request: PushUpdateRequest) {
        return this.http.post(UrlConfig.pushUpdate, request).pipe(map(m => {
            return new PushUpdateResponse(m);
        }));
    }
}