import { IntValue } from "./int.value";

export class Corporate {
  id!: number;
  name!: string;
  logo!: any;
  shortName!: string;
  mailDomains!: string[];
  vkn!: string;
  contactName!: string;
  contactEmail!: string;
  contactPhone!: string;
  status!: boolean;
  createDate!: number;
  campaigns!: IntValue[];
  modules!: number[];

  public clone(r: Corporate) {
    this.id = r.id;
    this.name = r.name;
    this.shortName = r.shortName;
    this.mailDomains = r.mailDomains;
    this.vkn = r.vkn;
    this.contactPhone = r.contactPhone;
    this.contactName = r.contactName;
    this.contactEmail = r.contactEmail;
    this.status = r.status;
    this.createDate = r.createDate;
    this.logo = r.logo;
    this.campaigns = r.campaigns;
    this.modules = r.modules;
  }
}
