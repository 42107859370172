import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, NgModel } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd/message";
import { LoginService } from "src/app/service/login.service";

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.less']
})
export class OtpComponent implements OnInit {

  otp: string[] = ['', '', '', ''];
  showLoading = false;
  countdown: number = 90;
  username: string = '';
  password: string = '';

  @ViewChild('otpInput1') otpInput1!: NgModel;
  @ViewChild('otpInput2') otpInput2!: NgModel;
  @ViewChild('otpInput3') otpInput3!: NgModel;
  @ViewChild('otpInput4') otpInput4!: NgModel;

  constructor(private fb: FormBuilder, private loginService: LoginService, private router: Router, private message: NzMessageService, private param: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.startCountdown();
  }

  onInput(event: any, nextInput: NgModel) {
    const input = event.target as HTMLInputElement;
    const maxLength = parseInt(input.getAttribute('maxlength')!, 10);

    if (input.value.length >= maxLength) {
      nextInput.control?.markAsTouched();
      nextInput.control?.markAsDirty();
      nextInput.control?.updateValueAndValidity();

      const nextElement = input.nextElementSibling as HTMLInputElement;
      if (nextElement) {
        nextElement.focus();
      }
    }
  }

  onBackspace(event: any, currentInput: NgModel, previousInput: NgModel) {
    const keyboardEvent = event as KeyboardEvent;

    if (currentInput.value === '' && keyboardEvent.key === 'Backspace') {
      event.preventDefault();

      previousInput.control?.markAsTouched();
      previousInput.control?.markAsDirty();
      previousInput.control?.updateValueAndValidity();

      const previousElement = (keyboardEvent.target as HTMLInputElement)?.previousElementSibling as HTMLInputElement;
      if (previousElement) {
        previousElement.focus();
        previousElement.value = '';
        previousInput.control?.reset('');
      }
    }
  }

  submitOtpForm() {
    const enteredOtp = this.otp.join('');

    this.showLoading = true;
    this.loginService.login({
      username: this.loginService.username,
      password: this.loginService.password,
      key: enteredOtp,
    }).subscribe((r) => {
      if (r.token != null) {
        this.showLoading = false;
        this.loginService.username = '';
        this.loginService.password = '';
        this.router.navigate(['dashboard'])
      } else {
        this.showLoading = false;
        this.message.error('Doğrulama anahtarı geçersiz');
      }
    }, error => {
      this.showLoading = false;
      this.message.error('Doğrulama anahtarı geçersiz');
    })
  }

  startCountdown() {
    const timer = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(timer);
      }
    }, 1000);
  }
}