import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlConfig } from "../config/url";
import { SurveyQueryResponse } from "../model/response/survey.query.response";
import { map } from "rxjs";
import { SurveyRegisterRequest } from "../model/request/survey.register.request";
import { SurveyRegisterResponse } from "../model/response/survey.register.response";
import { SurveyUpdateRequest } from "../model/request/survey.update.request";
import { SurveyUpdateResponse } from "../model/response/survey.update.response";
import { SurveyTypeListResponse } from "../model/response/survey.type.list.response";

@Injectable()
export class SurveyService {
  constructor(private http: HttpClient) {
  }

  query(corp?: number) {
    console.log(corp)
    console.log(corp != null)
    return this.http.post(UrlConfig.surveyQuery, {"corporateId": corp != null ? corp : null}).pipe(map(m => {
      return new SurveyQueryResponse(m);
    }));
  }

  register(request: SurveyRegisterRequest) {
    return this.http.post(UrlConfig.surveyRegister, request).pipe(map(m => {
      return new SurveyRegisterResponse(m);
    }));
  }

  update(request: SurveyUpdateRequest) {
    return this.http.post(UrlConfig.surveyUpdate, request).pipe(map(m => {
      return new SurveyUpdateResponse(m);
    }));
  }

  listTypes() {
    return this.http.get(UrlConfig.surveyTypeList).pipe(
      map(m => {
        return new SurveyTypeListResponse(m)
      })
    );
  }

  getAnswerData(surveyId: number) {
    const formData = new FormData();
    formData.append("surveyId", surveyId.toPrecision());
    return this.http.post(UrlConfig.surveyReport, formData, { responseType: 'blob' } );
  }
}