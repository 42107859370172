<div nz-row style="margin: 20px 5px 20px 0">
  <div nz-col nzSpan="10"><nz-input-group nzCompact>
    <nz-select [(ngModel)]="searchType" style="width: 150px" (ngModelChange)="searchTypeChange()">
      <nz-option [nzLabel]="'Firma Adı'" [nzValue]="'supplier'"></nz-option>
      <nz-option [nzLabel]="'Kampanya Tipi'" [nzValue]="'campaignType'"></nz-option>
      <nz-option [nzLabel]="'Kampanya Başlığı'" [nzValue]="'campaignTitle'"></nz-option>
    </nz-select>
    <nz-select [(ngModel)]="searchValue" style="width: 150px" *ngIf="searchType=='campaignType'">
      <nz-option [nzLabel]="'Cashback'" [nzValue]="'cashback'"></nz-option>
      <nz-option [nzLabel]="'İndirim'" [nzValue]="'discount'"></nz-option>
      <nz-option [nzLabel]="'İndirim (Tutar)'" [nzValue]="'amount'"></nz-option>
    </nz-select>
    <input type="text" nz-input [(ngModel)]="searchValue" style="width:50%;"  *ngIf= "searchType!=='campaignType'"/>
  </nz-input-group>
  <div nz-row nzSpan="3">
  <label nz-checkbox [(ngModel)]="hidePassive" style="margin-top: 10px" (ngModelChange)="hidePassiveCampaign()">Pasifleri Gizle</label>
  <label nz-checkbox [(ngModel)]="hideActive" style="margin-top: 10px" (ngModelChange)="hideActiveCampaign()">Aktifleri Gizle</label>
  </div>
  </div>
  <div nz-col nzSpan="3"><button nz-button  nzType="primary" (click)="search()">Ara</button>
    <button nz-button  nzType="default" style="margin-left: 5px" (click)="reset()">Sıfırla</button>
  </div>
</div>

<nz-table #campaignTable [nzData]="listOfDisplayData" [nzLoading]="tableDataLoading" nzPaginationType="small" [nzPageSize]="10" nzBordered
          nzShowSizeChanger>
  <thead>
  <tr>
    <th> Id</th>
    <th>Firma Adı</th>
    <th>Başlık</th>
    <th>Kategori</th>
    <th>Üye Firmalar</th>
    <th>Bitiş Tarihi</th>
    <th>Kupon Kullanımı</th>
    <th>Durum</th>
    <th nzWidth="200px">İşlemler</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of campaignTable.data">
    <ng-container>
      <td>
        {{data.id}}
      </td>
      <td>

        {{ data.supplier }}
      </td>
      <td>

        {{ data.title }}
      </td>
      <td>
        {{data.category.text}}
      </td>
      <td>
        <nz-select [ngModel]="data.corporatesInt"
          [nzMaxTagCount]="2"
          [nzMaxTagPlaceholder]="attributeTagPlaceHolder" nzMode="multiple" nzPlaceHolder="Üye Firmalar">
          <nz-option disabled=""
            *ngFor="let option of corporateList"
            [nzLabel]="option.text"
            [nzValue]="option.id"
            [nzDisabled]="true"
          ></nz-option>
        </nz-select>
      </td>
      <td>
        <div>{{ data.endDate|date:'dd.MM.yyyy HH:mm' }}</div>
      </td>
      <td>
        <nz-tag [nzColor]="data.totalCodeCount == 0 ? '#cccccc' : ((data.totalCodeCount - data.availableCodeCount)/data.totalCodeCount) * 100 >= 90 ?'#f50': ((data.totalCodeCount - data.availableCodeCount)/data.totalCodeCount) * 100 >= 40 ? '#ede96f' : '#87d068'">
          {{ data.totalCodeCount - data.availableCodeCount}} / {{ data.totalCodeCount }}
        </nz-tag>
      </td>
      <td>
        <nz-tag [nzColor]="data.status?'#87d068':'#f50'">{{ data.status ? 'Aktif' : 'Pasif' }}</nz-tag>
      </td>

      <td>

        <button (click)="showViewModal(data)" nz-button nzShape= "circle" nzType= "primary" style="font-weight: bold; margin-left: 10px; margin-right: 10px">
          <i class="icon"
             nz-icon
             nzType="eye"></i>
        </button>
        <button (click)="showAddCouponModal(data)" nz-button nzShape="round" nzType="dashed">İndirim Kodu</button>

      </td>

    </ng-container>
  </tr>
  </tbody>
</nz-table>

<button (click)="showAddCampaignModal()" nz-button nzShape="round" nzType="primary" style="margin-top: 10px">Kampanya
  ekle <i nz-icon nzType="plus"></i></button>

<nz-modal (nzOnCancel)="addCampaignModalCancel()" (nzOnOk)="addCampaignModalOk()"
          [(nzVisible)]="addCampaignModalVisible" nzOkText="Kaydet">
  <ng-container *nzModalTitle>
    <span style="margin-right: 10px; font-weight: bold">Kampanya Ekle</span>
  </ng-container>


  <ng-container *nzModalContent>
  <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">
      <nz-descriptions nzBordered nzSize="small">
        <nz-descriptions-item nzSpan="5" nzTitle="Firma">
          <nz-select [(ngModel)]="newCampaign.supplierId" nzAllowClear nzPlaceHolder="Firma Seç" nzShowSearch
                    style="width: 150px">
            <nz-option *ngFor="let c of supplierList" [nzLabel]="c.text" [nzValue]="c.id"></nz-option>
          </nz-select>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Kampanya Görseli"><input (change)="onFileChanged($event)"
                                                                          accept="image/x-png,image/jpeg"
                                                                          type="file"></nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="Başlık">
          <input [(ngModel)]="newCampaign.title"
                nz-input
                type="text">
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="Açıklama">
          <textarea [(ngModel)]="newCampaign.description"
                nz-input
                rows="4"></textarea>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="Koşullar">
          <textarea [(ngModel)]="newCampaign.conditions"
                nz-input
                rows="4"></textarea>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Kampanya Türü">
          <nz-select [(ngModel)]="campaignType">
            <nz-option *ngFor="let c of typeList" [nzLabel]="c.text" [nzValue]="c.value"></nz-option>
          </nz-select>
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="campaignType!='cashback'" nzSpan="5" nzTitle="Kupon Limiti">
        <input [(ngModel)]="newCampaign.couponLimit"
               nz-input
               step="1"
               type="number">
      </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="Komisyon Oranı">
          <input [(ngModel)]="newCampaign.commission"
                nz-input
                step="0.01"
                type="number">
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" [nzTitle]=campaignRateTitle()>
          <input [(ngModel)]="newCampaign.cashBackRatio"
                nz-input
                step="0.01"
                type="number">
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Etiket Gizle">
          <label nz-checkbox [(ngModel)]="newCampaign.tag">Etiketi Boş Göster</label>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="Kampanya Başlangıç">
          <nz-date-picker nzShowTime [(ngModel)]="newCampaign.startDate"
                          [nzFormat]="'dd.MM.yyyy HH:mm:ss'"></nz-date-picker>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="Kampanya Bitiş">
          <nz-date-picker nzShowTime [(ngModel)]="newCampaign.endDate"
                          [nzFormat]="'dd.MM.yyyy HH:mm:ss'"></nz-date-picker>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5"
                              nzTitle="Geçerli Olan Firmalar">
          <nz-select [(ngModel)]="newCampaign.corporates"
                    [nzMaxTagCount]="1"
                    [nzMaxTagPlaceholder]="attributeTagPlaceHolder" nzMode="multiple" nzPlaceHolder="Firma Ekle">
            <nz-option
              *ngFor="let option of corporateList"
              [nzLabel]="option.text"
              [nzValue]="option.id"
            ></nz-option>
          </nz-select>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Durum">
          <nz-select [(ngModel)]="newCampaign.status">
            <nz-option [nzLabel]="'Aktif'" [nzValue]="true"></nz-option>
            <nz-option [nzLabel]="'Pasif'" [nzValue]="false"></nz-option>
          </nz-select>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Geçerli Konum">
          <nz-checkbox-group (ngModelChange)="locationChange()" [(ngModel)]="locationOptions"></nz-checkbox-group>
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="webIsChecked" nzSpan="4" nzTitle="Web Sitesi">
          <input [(ngModel)]="newCampaign.webSite"
                nz-input
                type="text">
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Bildirim">
          <label nz-checkbox [(ngModel)]="notificationIsEnabled">Bildirim Gönder</label>
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="notificationIsEnabled" nzSpan="4" nzTitle="Bildirim Başlığı">
          <input [(ngModel)]="newCampaign.notificationTitle"
                nz-input
                type="text">
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="notificationIsEnabled" nzSpan="4" nzTitle="Bildirim İçeriği">
          <input [(ngModel)]="newCampaign.notificationContent"
                nz-input
                type="text">
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="notificationIsEnabled" nzSpan="4" nzTitle="Bildirim Tarihi">
          <nz-date-picker nzShowTime nzFormat="dd.MM.yyyy HH:mm:ss" 
                          [(ngModel)]="newCampaign.notificationDate"></nz-date-picker>
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-spin>
  </ng-container>

</nz-modal>
<ng-template #attributeTagPlaceHolder let-selectedList> +{{ selectedList.length }}  </ng-template>


<nz-modal [nzWidth]="600" (nzOnCancel)="viewModalCancel()" (nzOnOk)="viewModalOk()"  nzOkText="Kaydet" [(nzVisible)]="viewModalVisible" >
  <ng-container *nzModalTitle>
    <span style="margin-right: 10px">{{selectedCampaign.title.substring(0,32)}} </span>
    <a (click)="startEdit()" *ngIf="!editing" style="font-weight: bold"><i
            class="icon" nz-icon
            nzType="edit"></i></a>
    <a (click)="cancelEdit()" *ngIf="editing" style="font-weight: bold"><i
            class="icon" nz-icon
            nzType="close"></i></a>
  </ng-container>

  <ng-container *nzModalContent>
    <ng-container *ngIf="!editing; else editTemplate">

      <nz-descriptions nzBordered nzSize="small">
        <nz-descriptions-item nzSpan="4" nzTitle="Logo"><img [src]="selectedCampaign.logo"
                                                             alt="{{selectedCampaign.title}} logo"
                                                             height="64" width="256">
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="ID">{{selectedCampaign.id}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Başlık">{{selectedCampaign.title}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Firma Adı">{{selectedCampaign.supplier}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Kategori">{{selectedCampaign.category.text}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Açıklama">
          <textarea rows="4" readOnly nz-input>{{selectedCampaign.description}}</textarea>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Koşullar">
          <textarea rows="4" readOnly nz-input>{{addDashes(selectedCampaign.conditions)}}</textarea>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Kampanya Türü">{{campaignTypeBody()}}</nz-descriptions-item>
        <nz-descriptions-item *ngIf="selectedCampaign.campaignType!='cashback'" nzSpan="4" nzTitle="Kupon Limiti">{{selectedCampaign.couponLimit}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Komisyon">{{selectedCampaign.commission}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                              [nzTitle]=campaignRateTitle()>{{selectedCampaign.cashBackRatio}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                              nzTitle="Etiket">{{selectedCampaign.tag ? "Görünmez" : "Görünür"}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                              nzTitle="Başlangıç tarihi">{{selectedCampaign.startDate|date:'dd.MM.yyyy HH:mm:ss'}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                              nzTitle="Bitiş tarihi">{{selectedCampaign.endDate|date:'dd.MM.yyyy HH:mm:ss'}}</nz-descriptions-item>

        <nz-descriptions-item nzSpan="4"
                              nzTitle="Geçerli Firmalar">
          <nz-select  [(ngModel)]="selectedCampaignCorporateList"
                     [nzMaxTagCount]="2"
                     [nzMaxTagPlaceholder]="attributeTagPlaceHolder" nzMode="multiple" nzPlaceHolder="Firma Ekle">
            <nz-option disabled=""
                    *ngFor="let option of corporateList"
                    [nzLabel]="option.text"
                    [nzValue]="option.id"
            ></nz-option>
          </nz-select>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Geçerli Konum">
          <nz-tag *ngFor="let location of selectedCampaign.locations">
            <i nz-icon *ngIf="location.value=='store'" nzType="shop" nzTheme="outline"></i>
            <i nz-icon *ngIf="location.value=='web'" nzType="shopping" nzTheme="outline"></i>
            {{location.text}}</nz-tag>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Online Mağaza" *ngIf="isWeb()">
          {{selectedCampaign.webSite}}
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                              nzTitle="Durum">{{ selectedCampaign.status ? 'Aktif' : 'Pasif' }}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                              nzTitle="Oluşturulma Zamanı">{{selectedCampaign.createDate|date:'dd.MM.yyyy HH:mm:ss'}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                *ngIf="selectedCampaign.notificationTitle != null || selectedCampaign.notificationContent != null || selectedCampaign.notificationDate != null" 
                              nzTitle="Bildirim Başlığı">{{selectedCampaign.notificationTitle}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                *ngIf="selectedCampaign.notificationTitle != null || selectedCampaign.notificationContent != null || selectedCampaign.notificationDate != null" 
                              nzTitle="Bildirim İçeriği">{{selectedCampaign.notificationContent}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                *ngIf="selectedCampaign.notificationTitle != null || selectedCampaign.notificationContent != null || selectedCampaign.notificationDate != null" 
                              nzTitle="Bildirim Tarihi">{{selectedCampaign.notificationDate|date:'dd.MM.yyyy HH:mm:ss'}}</nz-descriptions-item>

      </nz-descriptions>
    </ng-container>

  </ng-container>

</nz-modal>

<ng-template #editTemplate>
  <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">

    <nz-descriptions nzBordered nzSize="small">
      <nz-descriptions-item nzSpan="4" nzTitle="Logo"><input (change)="onFileChanged($event)"
                                                             accept="image/x-png,image/jpeg"
                                                             type="file">
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="ID">{{selectedCampaign.id}}</nz-descriptions-item>

      <nz-descriptions-item nzSpan="4" nzTitle="Başlık"><input [(ngModel)]="selectedCampaign.title" nz-input
                                                                  type="text"></nz-descriptions-item>

      <nz-descriptions-item nzSpan="4" nzTitle="Firma Adı"><input [(ngModel)]="selectedCampaign.supplier" nz-input
                                                                  type="text"></nz-descriptions-item>

      <nz-descriptions-item nzSpan="4" nzTitle="Kategori">{{selectedCampaign.category.text}}</nz-descriptions-item>

      <nz-descriptions-item nzSpan="4" nzTitle="Açıklama"><textarea [(ngModel)]="selectedCampaign.description" nz-input
                                                                         rows="4"></textarea></nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Koşullar"><textarea [(ngModel)]="selectedCampaign.conditions" nz-input
                                                                         rows="4"></textarea></nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Kampanya Türü">
        <nz-select [(ngModel)]="selectedCampaign.campaignType">
          <nz-option nzValue="cashback" nzLabel="Cashback"></nz-option>
          <nz-option nzValue="discount" nzLabel="İndirim"></nz-option>
          <nz-option nzValue="amount" nzLabel="İndirim (Tutar)"></nz-option>
        </nz-select>
      </nz-descriptions-item>
      <nz-descriptions-item *ngIf="selectedCampaign.campaignType!='cashback'" nzSpan="5" nzTitle="Kupon Limiti">
        <input [(ngModel)]="selectedCampaign.couponLimit"
               nz-input
               step="1"
               type="number">
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="5" nzTitle="Komisyon">
        <input [(ngModel)]="selectedCampaign.commission"
               nz-input
               step="0.01"
               type="number">
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="5" [nzTitle]=campaignRateTitle()>
        <input [(ngModel)]="selectedCampaign.cashBackRatio"
               nz-input
               step="0.01"
               type="number">
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Etiket Gizle">
        <label nz-checkbox [(ngModel)]="selectedCampaign.tag">Etiketi Boş Göster</label>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="5" nzTitle="Kampanya Başlangıç">
        <nz-date-picker nzShowTime [(ngModel)]="selectedCampaign.startDate"
                        [nzFormat]="'dd.MM.yyyy HH:mm:ss'"></nz-date-picker>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="5" nzTitle="Kampanya Bitiş">
        <nz-date-picker nzShowTime [(ngModel)]="selectedCampaign.endDate"
                        [nzFormat]="'dd.MM.yyyy HH:mm:ss'"></nz-date-picker>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="5"
                            nzTitle="Geçerli Olan Firmalar">
        <nz-select [(ngModel)]="selectedCampaignCorporateList"
                   [nzMaxTagCount]="2"
                   [nzMaxTagPlaceholder]="attributeTagPlaceHolder" nzMode="multiple" nzPlaceHolder="Firma Ekle">
          <nz-option
                  *ngFor="let option of corporateList"
                  [nzLabel]="option.text"
                  [nzValue]="option.id"
          ></nz-option>
        </nz-select>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Durum">
        <nz-select [(ngModel)]="selectedCampaign.status">
          <nz-option [nzLabel]="'Aktif'" [nzValue]="true"></nz-option>
          <nz-option [nzLabel]="'Pasif'" [nzValue]="false"></nz-option>
        </nz-select>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Geçerli Konum">
        <nz-checkbox-group (ngModelChange)="locationChangeUpdate($event)" [(ngModel)]="locationOptions"></nz-checkbox-group>
      </nz-descriptions-item>
      <nz-descriptions-item *ngIf="webIsChecked" nzSpan="4" nzTitle="Web Sitesi">
        <input [(ngModel)]="selectedCampaign.webSite"
               nz-input
               type="text">
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Bildirim">
        <label nz-checkbox [(ngModel)]="notificationIsEnabled">Bildirim Gönder</label>
      </nz-descriptions-item>
      <nz-descriptions-item *ngIf="notificationIsEnabled" nzSpan="4" nzTitle="Bildirim Başlığı">
        <input [(ngModel)]="selectedCampaign.notificationTitle"
              nz-input
              type="text">
      </nz-descriptions-item>
      <nz-descriptions-item *ngIf="notificationIsEnabled" nzSpan="4" nzTitle="Bildirim İçeriği">
        <input [(ngModel)]="selectedCampaign.notificationContent"
              nz-input
              type="text">
      </nz-descriptions-item>
      <nz-descriptions-item *ngIf="notificationIsEnabled" nzSpan="4" nzTitle="Bildirim Tarihi">
        <nz-date-picker nzShowTime nzFormat="dd.MM.yyyy HH:mm:ss" 
                        [(ngModel)]="selectedCampaign.notificationDate"></nz-date-picker>
      </nz-descriptions-item>


    </nz-descriptions>


  </nz-spin>
</ng-template>

<nz-modal (nzOnCancel)="addCouponModalCancel()" (nzOnOk)="addCouponModalOk()" 
          [(nzVisible)]="addCouponModalVisible" nzOkText="Kaydet">
  <ng-container *nzModalTitle>
    <span style="margin-right: 10px; font-weight: bold">
      {{selectedCampaign.supplier}} İndirim Kodu {{!showCouponDetail ?"Ekle" : "Tanımları"}}</span>
    <a (click)="startCouponDetail()" *ngIf="!showCouponDetail" style="font-weight: bold"><i
            class="icon" nz-icon
            nzType="file-search"></i></a>
    <a (click)="cancelCouponDetail()" *ngIf="showCouponDetail" style="font-weight: bold"><i
            class="icon" nz-icon
            nzType="close"></i></a>
  </ng-container>


  <ng-container *nzModalContent>
    <ng-container *ngIf="!showCouponDetail; else couponCodeDetailTemplate">
    <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">
      <nz-descriptions nzBordered nzSize="small">
        
        <nz-descriptions-item nzSpan="5" nzTitle="İndirim Kodu">
          <input type="file" (change)="getCouponFile($event)" class="file"
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
          <td>
            <small>(İndirim kodlarının ilk sütununda bulduğundan emin olun.)</small>
          </td>
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-spin>
    </ng-container>
  </ng-container>

</nz-modal>

<ng-template #couponCodeDetailTemplate>
  <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">
    <nz-table #detailTable
              [nzData]="couponDetailList!"
              [nzFrontPagination]="false"
              [nzShowPagination]="false"
              nzBordered>
      
    
              <nz-descriptions nzBordered nzSize="small">

                <nz-descriptions-item nzSpan="5" nzTitle="Kupon Kodu Listesi">
                  <button (click)="downloadCouponFile()">Download File</button>
                </nz-descriptions-item>
        
              </nz-descriptions>
    </nz-table>
  </nz-spin>
</ng-template>
