import {Injectable, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CampaignSaveRequest} from "../model/request/campaign.save.request";
import {UrlConfig} from "../config/url";
import {map} from "rxjs/operators";
import {CampaignSaveResponse} from "../model/response/campaignSaveResponse";
import {CampaignQueryResponse} from "../model/response/campaign.query.response";
import {CampaignUpdateRequest} from "../model/request/campaign.update.request";
import {CampaignCorporatesResponse} from "../model/response/campaign.corporates.response";
import {CampaignGetCouponDetailResponse} from "../model/response/campaign.get.coupon.detail";
import { CampaignListResponse } from "../model/response/campaign.list.response";
import { CampaignTypesListResponse } from "../model/response/campaign.types.list.response";

@Injectable()
export class CampaignService implements OnInit {
  constructor(private http: HttpClient) {

  }

  ngOnInit(): void {
  }

  save(request: CampaignSaveRequest) {
    return this.http.post(UrlConfig.campaignSave, request).pipe(map(m => new CampaignSaveResponse(m)));
  }

  update(request: CampaignUpdateRequest) {
    return this.http.post(UrlConfig.campaignUpdate, request).pipe(map(m => new CampaignSaveResponse(m)));
  }

  query() {
    return this.http.post(UrlConfig.campaignQuery, {}).pipe(map(m => new CampaignQueryResponse(m)));
  }

  campaignCorporates(id:number){
    return this.http.get(UrlConfig.campaignCorporateList+id).pipe(map(m => new CampaignCorporatesResponse(m)));
  }

  uploadLogo(logo: File, id: number) {
    const formData = new FormData();
    formData.append("file", logo);
    formData.append("id", id.toString());
    return this.http.post(UrlConfig.campaignUploadImage, formData);
  }

  getImage(id:number) {
    return this.http.get(UrlConfig.campaignDownloadImage+id,
      { responseType: 'blob' });
  }

  addCouponCode(file: File, campaign: number) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("campaign", campaign.toPrecision());
    return this.http.post(UrlConfig.campaignAddCouponCode,formData).pipe(map(m => new CampaignCorporatesResponse(m)));
  }

  getCouponDetail(campaignId: number){
    const formData = new FormData();
    formData.append("campaignId", campaignId.toPrecision());
    return this.http.post(UrlConfig.campaignGetCouponDetail, formData).pipe(map(m => new CampaignGetCouponDetailResponse(m)));
  }

  getCouponFile(campaignId: number) {
    const formData = new FormData();
    formData.append("campaignId", campaignId.toPrecision());
    return this.http.post(UrlConfig.campaignDownloadCouponFile, formData,
       { responseType: 'blob' } );
  }

  list() {
    return this.http.get(UrlConfig.campaignList).pipe(
      map(m => {
        return new CampaignListResponse(m)
      })
    );
  }

  listTypes() {
    return this.http.get(UrlConfig.campaignTypesList).pipe(
      map(m => {
        return new CampaignTypesListResponse(m)
      })
    );
  }

}
