export class Serializable {
  code!: string;
  messages!: [];

  constructor(json?: any) {
    if (json) {
      Object.assign(this, json);
    }
  }

  isOk(): boolean {
    return this.code == 'OK';
  }
}
