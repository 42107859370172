import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UrlConfig} from "../config/url";
import {map} from "rxjs/operators";
import {SupplierQueryResponse} from "../model/response/supplier.query.response";
import {Supplier} from "../model/supplier";
import {SupplierRegisterRequest} from "../model/request/supplier.register.request";
import {SupplierRegisterResponse} from "../model/response/supplier.register.response";
import {CategoryListResponse} from "../model/response/category.list.response";
import {SupplierUpdateRequest} from "../model/request/supplier.update.request";
import {SupplierUpdateResponse} from "../model/response/supplier.update.response";
import {SupplierListResponse} from "../model/response/supplier.list.response";
import {SupplierAddPosResponse} from "../model/response/supplier.add.pos.response";

@Injectable()
export class SupplierService {
  constructor(private http: HttpClient) {
  }

  query() {
    return this.http.get(UrlConfig.supplierQuery).pipe(map(m => {
      return new SupplierQueryResponse(m);
    }));
  }

  list() {
    return this.http.get(UrlConfig.supplierList).pipe(map(m => {
      return new SupplierListResponse(m);
    }));
  }

  register(supplier: Supplier) {
    return this.http.post(UrlConfig.supplierRegister, new SupplierRegisterRequest(supplier)).pipe(map(m => {
      return new SupplierRegisterResponse(m);
    }));
  }

  update(supplier: Supplier) {
    return this.http.post(UrlConfig.supplierUpdate, new SupplierUpdateRequest(supplier)).pipe(map(m => {
      return new SupplierUpdateResponse(m);
    }));
  }

  categoryList() {
    return this.http.get(UrlConfig.categoryAllList).pipe(map(m => {
      return new CategoryListResponse(m);
    }))
  }

  getLogo(id: number) {
    return this.http.get(UrlConfig.supplierLogo + id,
      {responseType: 'blob'});
  }

  uploadLogo(logo: File, id: number) {
    const formData = new FormData();
    formData.append("file", logo);
    formData.append("id", id.toString());
    return this.http.post(UrlConfig.supplierUploadLogo, formData);
  }

  addPos(file: File, supplierId: number) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("supplierId", supplierId.toPrecision());
    return this.http.post(UrlConfig.supplierAddPos, formData).pipe(map(c => new SupplierAddPosResponse(c)));
  }
  
  downloadRegistrationFile(supplierId: number){
    const formData = new FormData();
    formData.append("supplierId", supplierId.toPrecision());
    return this.http.post(UrlConfig.supplierDownloadPos, formData,
      { responseType: 'blob' });
  }
}
