import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { IntValue } from 'src/app/model/int.value';
import { Push } from 'src/app/model/push';
import { PushRegisterRequest } from 'src/app/model/request/push.register.request';
import { PushUpdateRequest } from 'src/app/model/request/push.update.request';
import { CorporateService } from 'src/app/service/corporate.service';
import { HelperService } from 'src/app/service/helper.service';
import { LocalStorageService } from 'src/app/service/local.storage.service';
import { PushService } from 'src/app/service/push.service';

@Component({
  selector: 'app-push',
  templateUrl: './push.component.html',
  styleUrls: ['./push.component.less']
})
export class PushComponent implements OnInit {

  tableDataLoading = false;
  isUpdating = false;
  pushList!: Push[];
  newPush!: PushRegisterRequest;
  addPushModalVisible = false;
  corporateList!: IntValue[];
  role !: string;
  viewModalVisible = false;
  selectedPush!: Push;
  editing = false;

  constructor(private notification: NzNotificationService, private corporateService: CorporateService, private localStorage: LocalStorageService, private pushService: PushService, private helperService: HelperService) {
    this.role = localStorage.get('role') ?? "";
  }

  ngOnInit(): void {
    this.query();
    this.corporateService.list().subscribe(c => {
      this.corporateList = c.list;
    });
  }

  query() {
    this.tableDataLoading = true;
    this.pushService.query().subscribe(c => {
      this.pushList = c.pushes;
      this.tableDataLoading = false;
    });
  }

  showAddPushModal() {
    this.addPushModalVisible = true;
    this.newPush = new PushRegisterRequest();
  }

  addPushModalCancel() {
    this.addPushModalVisible = false;
  }

  addPushModalOk() {
    this.newPush.shareDate = this.helperService.dateTimezone(this.newPush.shareDate);
    if(this.newPush.title != null && this.newPush.content != null && this.newPush.shareDate != null) {
      this.isUpdating = true;
      this.pushService.register(this.newPush).subscribe(m => {
        if(m.isOk()) {
          //this.isUpdating = false;
          this.addPushModalVisible = false;
          this.query()
        }
      });
      this.isUpdating = false;
    }else {
      this.notification.error('Hata', 'Zorunlu alanları eksiksiz doldurunuz.');
    }
  }

  showViewModal(data: Push) {
    this.selectedPush = Object.assign({}, data);
    this.viewModalVisible = true;
  }

  viewModalCancel() {
    this.viewModalVisible = false;
    this.editing = false;
  }

  viewModalOk() {
    if(this.selectedPush.status != 2) { //if it is not sent status
      var request = new PushUpdateRequest();
      request.id = this.selectedPush.id;
      request.title = this.selectedPush.title;
      request.content = this.selectedPush.content;
      request.status = this.selectedPush.status;
      request.shareDate = this.helperService.dateTimezone(this.selectedPush.shareDate);
      request.corporates = this.selectedPush.corporates;
      
      if(request.title != null && request.content != null && request.shareDate != null) {
        this.isUpdating = true;
        this.pushService.update(request).subscribe(s => {
          if(s.isOk()) {
            this.query();
            this.viewModalVisible = false;
            this.editing = false;
          }
          this.isUpdating = false;
        });
      }else {
        this.notification.error('Hata', 'Zorunlu alanları eksiksiz doldurunuz.');
      }
    }else {
      this.viewModalVisible = false;
      this.editing = false;
    }
  }

  startEdit() {
    this.isUpdating = false;
    this.editing = true;
  }

  cancelEdit() {
    this.editing = false;
  }

  onDateChanged(event: any) {
    if (this.disabledHours().includes(event.getHours())) {
      event.setHours(10);
    }
    if (event.getMinutes() != 30 || event.getMinutes() != 0) {
      event.setMinutes(0);
    }
    event.setSeconds(0);
  }

  disabledHours(): number[] {
    return [20, 21, 22, 23, 0, 1, 2, 3, 4, 5];
  }

  disabledMinutes(): number[] {
    var list = []
    for (var i = 1; i < 60; i++) {
      if (i != 30) {
        list.push(i);
      }
    }
    return list;
  }
}
