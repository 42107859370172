import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Announcement } from 'src/app/model/announcement';
import { IntValue } from 'src/app/model/int.value';
import { AnnouncementRegisterRequest } from 'src/app/model/request/announcement.register.request';
import { AnnouncementUpdateRequest } from 'src/app/model/request/announcement.update.request';
import { AnnouncementService } from 'src/app/service/announcement.service';
import { CorporateService } from 'src/app/service/corporate.service';
import { LocalStorageService } from 'src/app/service/local.storage.service';
import { lastValueFrom } from 'rxjs';
import { HelperService } from 'src/app/service/helper.service';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.less']
})
export class AnnouncementComponent implements OnInit {

  tableDataLoading = false;
  isUpdating = false;
  announcementList!: Announcement[];
  addAnnouncementModalVisible = false;
  newAnnouncement!: AnnouncementRegisterRequest;
  viewModalVisible = false;
  selectedAnnouncement!: Announcement;
  editing = false;
  status!: number;
  corporateList!: IntValue[];
  role !: string;
  selectedImage?: File;

  constructor(private announcementService: AnnouncementService, private notification: NzNotificationService, private corporateService: CorporateService, private localStorage: LocalStorageService, private helperService: HelperService) {
    this.role = localStorage.get('role') ?? "";
  }

  ngOnInit(): void {
    this.query();
    this.corporateService.list().subscribe(c => {
      this.corporateList = c.list;
    });
  }

  query() {
    this.tableDataLoading = true;
    this.announcementService.query().subscribe(c => {
      this.announcementList = c.announcements;
      this.tableDataLoading = false;
    });
  }

  showAddAnnouncementModal() {
    this.newAnnouncement = new AnnouncementRegisterRequest();
    this.selectedImage = undefined;
    this.addAnnouncementModalVisible = true;
  }

  addAnnouncementModalCancel() {
    this.addAnnouncementModalVisible = false;
  }

  addAnnouncementModalOk() {
    this.newAnnouncement.shareDate = this.helperService.dateTimezone(this.newAnnouncement.shareDate);
    this.newAnnouncement.shareDate.setSeconds(0);
    if(this.newAnnouncement.title != null && this.newAnnouncement.content != null && this.newAnnouncement.shareDate != null) {
      this.isUpdating = true;
      var respOk = this.helperService.checkImageSize(this.selectedImage);
      if(respOk) {
        this.announcementService.register(this.newAnnouncement).subscribe(s => {
          if(s.isOk()) {
            if(this.selectedImage != null && this.selectedImage != undefined) {
              this.announcementService.uploadImage(this.selectedImage!, s.id).subscribe(ret => {});
            }
            this.addAnnouncementModalVisible = false;
            this.query();
          }
          this.isUpdating = false;
        });
      }else {
        this.isUpdating = false;
      }
    }else {
      this.notification.error('Hata', 'Zorunlu alanları eksiksiz doldurunuz.');
    }
  }

  onFileChanged(event: any) {
    let file = event.target.files[0];
    if (file != undefined && (file.type == 'image/png' || file.type == 'image/jpeg')) {
      this.selectedImage = file;
    } else {
      this.notification.error('Hata', 'Gerçerli formatta görsel yükleyiniz.(png)');
    }
  }

  showViewModal(data: Announcement) {
    this.selectedAnnouncement = Object.assign({}, data);
    this.selectedImage = undefined;
    this.status = data.status;

    lastValueFrom(this.announcementService.downloadImage(data.id)).then(v => {
      if (v.size != 0) {
        const reader = new FileReader();
        reader.onload = (e) => this.selectedAnnouncement.picture = e.target!.result;
        reader.readAsDataURL(v);
      }
      this.viewModalVisible = true;
    });
  }

  viewModalCancel() {
    this.viewModalVisible = false;
    this.editing = false;
  }

  viewModalOk() {
    this.isUpdating = true;
    var request = new AnnouncementUpdateRequest();
    request.id = this.selectedAnnouncement.id;
    request.title = this.selectedAnnouncement.title;
    request.content = this.selectedAnnouncement.content;
    request.status = this.selectedAnnouncement.status;
    request.shareDate = this.helperService.dateTimezone(this.selectedAnnouncement.shareDate);
    request.shareDate.setSeconds(0);
    var respOk = this.helperService.checkImageSize(this.selectedImage);

    if(respOk) {
      this.announcementService.update(request).subscribe(s => {
        if (s.isOk()) {
          if (this.selectedImage != null && this.selectedImage != undefined) {
            this.announcementService.uploadImage(this.selectedImage!, s.id).subscribe(ret => { });
          }
          this.query();
          this.viewModalVisible = false;
          this.editing = false;
        }
        this.isUpdating = false;
      })
    }else {
      this.isUpdating = false;
    }
    
  }

  onDateChanged(event: any) {
    if (this.disabledHours().includes(event.getHours())) {
      event.setHours(10);
    }
    if (event.getMinutes() != 30 || event.getMinutes() != 0) {
      event.setMinutes(0);
    }
    event.setSeconds(0);
  }

  startEdit() {
    this.isUpdating = false;
    this.editing = true;
  }

  cancelEdit() {
    this.editing = false;
  }

  disabledHours(): number[] {
    return [20, 21, 22, 23, 0, 1, 2, 3, 4, 5];
  }

  disabledMinutes(): number[] {
    var list = []
    for (var i = 1; i < 60; i++) {
      if (i != 30) {
        list.push(i);
      }
    }
    return list;
  }

}
