<nz-layout class="app-layout">
  <nz-sider [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" nzCollapsible>
    <div class="logo">Payeni Yönetim</div>
    <ul [nzInlineCollapsed]="isCollapsed" nz-menu nzMode="inline" nzTheme="light">
<!--      <li
        [nzTooltipTitle]="isCollapsed ? 'Ana Sayfa' : ''"
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"

      >
        <i class="menu-icon" nz-icon nzType="dashboard" nzTheme="outline"></i>
        <span routerLink="/dashboard">Ana Sayfa</span>
      </li>-->
      <li
        *ngIf="role==='ADMIN'"
        [nzTooltipTitle]="isCollapsed ? 'Kullanıcı İşlemleri' : ''"
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"

      >
        <i class="menu-icon" nz-icon nzTheme="outline" nzType="usergroup-delete"></i>
        <span routerLink="/users">Kullanıcı İşlemleri</span>
      </li>
      <li
        *ngIf="role==='ADMIN'"
        [nzTooltipTitle]="isCollapsed ? 'Kampanya İşlemleri' : ''"
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"

      >
        <i class="menu-icon" nz-icon nzTheme="outline" nzType="dollar-circle"></i>
        <span routerLink="/campaign">Kampanya İşlemleri</span>
      </li>
      <li
        *ngIf="role==='ADMIN'"
        [nzTooltipTitle]="isCollapsed ? 'Tedarikçi İşlemleri' : ''"
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"

      >
        <i class="menu-icon" nz-icon nzTheme="outline" nzType="experiment"></i>

        <span routerLink="/supplier">Tedarikçi İşlemleri</span>

      </li>
      <li
        *ngIf="role==='ADMIN'"
        [nzTooltipTitle]="isCollapsed ? 'Üye Firma İşlemleri' : ''"
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"
      >
        <i class="menu-icon" nz-icon nzTheme="outline" nzType="insert-row-above"></i>

        <span routerLink="/corporate">Üye Firma İşlemleri</span>

      </li>
      <li
        *ngIf="role==='ADMIN'"
        [nzTooltipTitle]="isCollapsed ? 'Cashback İşlemleri' : ''"
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"
      >
        <i class="menu-icon" nz-icon nzTheme="outline" nzType="file-sync"></i>

        <span routerLink="/cashback">Cashback İşlemleri</span>

      </li>
      <li
        *ngIf="role==='ADMIN' || role==='DELEGATE'"
        [nzTooltipTitle]="isCollapsed ? 'Cashback İşlemleri' : ''"
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"
      >
        <i class="menu-icon" nz-icon nzTheme="outline" nzType="notification"></i>

        <span routerLink="/announcement">Duyuru İşlemleri</span>

      </li>
      <li
        *ngIf="role==='ADMIN' || role==='DELEGATE'"
        [nzTooltipTitle]="isCollapsed ? 'Push İşlemleri' : ''"
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"
      >
        <i class="menu-icon" nz-icon nzTheme="outline" nzType="bell"></i>

        <span routerLink="/push">Push İşlemleri</span>

      </li>
      <li
        *ngIf="role==='ADMIN' || role==='DELEGATE'"
        [nzTooltipTitle]="isCollapsed ? 'Anket İşlemleri' : ''"
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"
      >
        <i class="menu-icon" nz-icon nzTheme="outline" nzType="file-add"></i>

        <span routerLink="/survey">Anket İşlemleri</span>

      </li>
      <li 
        *ngIf="role==='ADMIN'"
        [nzTitle]="titleTpl" nz-submenu>
        <ng-template #titleTpl><i class="menu-icon" nz-icon nzType="history"></i><span>Geçmiş</span></ng-template>
        <ul>
          <li nz-menu-item>Hesap Harketleri</li>
        </ul>
        <ul>
          <li nz-menu-item>Kullanıcı Haraketleri</li>
        </ul>
        <ul>
          <li [nzTooltipTitle]="isCollapsed ? 'Email Logları' : ''"
            nz-menu-item
            nz-tooltip
            nzTooltipPlacement="right"
            routerLink="/email-log">Email Logları</li>
        </ul>
      </li>

      <li *ngIf="role==='ADMIN'" [nzTitle]="settings" nz-submenu>
        <ng-template #settings><i class="menu-icon" nz-icon nzType="setting"></i><span>Ayarlar</span></ng-template>
        <ul>
          <li nz-menu-item>Sistem Ayarları</li>
        </ul>
        <ul>
          <li nz-menu-item>Kullancı Ayarları</li>
        </ul>
      </li>

    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <i
        (click)="isCollapsed = !isCollapsed"
        [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
        class="trigger"
        nz-icon
      ></i>
      <i style="float: right">
        <nz-button-group>
          <button [nzDropdownMenu]="profileDropdown" nz-button nz-dropdown nzPlacement="bottomCenter"
                  nzTrigger="click">
            <i nz-icon nzType="user"></i>
            {{fullName}}
          </button>
        </nz-button-group>
      </i>
    </nz-header>

    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
    <nz-footer> Payeni Yönetim Paneli | 2021</nz-footer>
  </nz-layout>
</nz-layout>

<nz-dropdown-menu #profileDropdown="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item><i class="icon" nz-icon nzType="user"></i> Profil</li>
    <nz-divider></nz-divider>
    <li nz-menu-item><a (click)="logout()"><i class="icon" nz-icon nzType="logout"></i> Çıkış</a></li>
  </ul>
</nz-dropdown-menu>
