import {Component, OnInit} from '@angular/core';
import {UserService} from "../../service/user.service";
import {User} from "../../model/user";
import {UserUpdateRequest} from "../../model/request/user.update.request";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {toNumber} from "ng-zorro-antd/core/util";
import {lastValueFrom} from "rxjs";
import { IntValue } from 'src/app/model/int.value';
import { CorporateService } from 'src/app/service/corporate.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.less']
})
export class UsersComponent implements OnInit {

  listOfData: User[] = [];
  listOfDisplayData: User[] = [];
  searchValue = '';
  visible = false;
  userModalVisible = false;
  selectedUser!: User;
  isUpdating = false;
  dataLoading = false;
  searchType='name';
  corporateList!: IntValue[];
  editing = false;
  constructor(private userService: UserService, private notification: NzNotificationService, private corporateService: CorporateService) {

  }

  ngOnInit(): void {
    this.queryUsers();
    this.corporateService.list().subscribe(c => {
      this.corporateList = c.list;
    });
  }

  queryUsers() {
    this.dataLoading = true;
    lastValueFrom(this.userService.query()).then(data => {
      this.listOfData = data.users;
      this.listOfDisplayData = [...this.listOfData];
      this.dataLoading = false;
    });
  }

  startEdit(id: number): void {
    this.editing = true;
    this.selectedUser.newPhone = this.selectedUser.phone;

  }

  cancelEdit(id: number): void {
    this.editing = false
  }

  saveEdit(id: number): void {
    const index = this.listOfData.findIndex(item => item.id === id);
    Object.assign(this.listOfData[index], this.selectedUser);
    this.editing = false;
  }

  reset(): void {
    this.searchValue = '';
    this.listOfDisplayData = this.listOfData;
  }

  search(): void {
    this.visible = false;
    this.listOfDisplayData = this.listOfData.filter((item: User) =>{

        switch (this.searchType){
          case 'name': return item.firstName != null && (item.firstName + item.lastName).toLowerCase().toString().replace("i̇", "i").indexOf(this.searchValue.toLowerCase()) !== -1;
          case 'id' : return item.id != null && (item.id.toString()).indexOf(this.searchValue) !== -1 && item.id.toString() === this.searchValue;
          case 'phone': return item.phone != null && (item.phone.toString()).indexOf(this.searchValue) !== -1;
          case 'corporate': return item.corporate != null && (item.corporate.toLowerCase().toString().replace("i̇", "i")).indexOf(this.searchValue.toLowerCase()) !== -1;
          case 'email': return item.email != null && (item.email).indexOf(this.searchValue) !== -1;
          case 'tc': return item.tckn != null && (item.tckn.toString()).indexOf(this.searchValue) !== -1;
          case 'status': return item.status != null && (item.status.toString()).indexOf(this.searchValue) !== -1;
          case 'role': return item.userRole != null && (item.userRole.value).indexOf(this.searchValue) !== -1;
          default: return item.firstName != null && (item.firstName + item.lastName).indexOf(this.searchValue) !== -1;
        }
    } );
  }

  modalCancel() {
    this.userModalVisible = false;
    this.cancelEdit(this.selectedUser.id);

  }

  modalOk() {
    this.isUpdating = true;
    this.selectedUser.email = '';
    var request = new UserUpdateRequest(this.selectedUser);
    lastValueFrom(this.userService.update(request)).then((res) => {
      if (!res.isOk()) {
        this.notification.error('Güncelleme Hatası', res.message);
      } else {
        this.queryUsers();
        this.userModalVisible = false;

      }
      this.isUpdating = false;
    });
  }

  showModal(data: User): void {
    this.selectedUser = new User(data);
    this.userModalVisible = true;

  }

  searchTypeChange() {
    this.searchValue='';
    switch (this.searchType){
      case 'role':this.searchValue='user';break;
      case 'status':this.searchValue='active'; break;
    }
  }

  downloadUsers() {
    this.isUpdating = true;
        lastValueFrom(this.userService.getUserFile()).then(v => {
            if (v.size != 0) {
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(v);
                link.download = "kullanici_rapor.xlsx";
                link.click();
                this.isUpdating = false;
            }
        });
  }

  downloadTransaction() {
    this.isUpdating = true;
        lastValueFrom(this.userService.getTransactionFile()).then(v => {
            if (v.size != 0) {
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(v);
                link.download = "transaction_rapor.xlsx";
                link.click();
                this.isUpdating = false;
            }
        });
  }
}
