export class UrlConfig {
  private static _basePath: String = "https://api.payeni.com/admin/";
  private static _serviceBasePath: String = "https://api.payeni.com/";
  //private static _basePath: String = "http://localhost:8081/admin/";
  static login = UrlConfig._basePath + 'user/login';
  static otpLogin = UrlConfig._basePath + 'user/otp-login';
  static  info = UrlConfig._basePath + 'user/info';
  static  query = UrlConfig._basePath + 'user/query';
  static  update = UrlConfig._basePath + 'user/update';
  static  userFile = UrlConfig._basePath + 'user/user-file';
  static  transactionFile = UrlConfig._basePath + 'user/transaction-file';

  static  corporateQuery = UrlConfig._basePath + 'corporate/query';
  static  corporateList = UrlConfig._basePath + 'corporate/list';
  static  corporateRegister = UrlConfig._basePath + 'corporate/save';
  static  corporateUpdate = UrlConfig._basePath + 'corporate/update';
  static  corporateLogo = UrlConfig._basePath + 'corporate/download-logo/';
  static  corporateUploadLogo = UrlConfig._basePath + 'corporate/upload-logo';
  static  corporateAddRegistration = UrlConfig._basePath + 'corporate/add-registration';
  static  corporateDownloadCouponFile = UrlConfig._basePath + 'corporate/registration-file';
  static  corporateCampaignList = UrlConfig._basePath + 'corporate/campaign/';
  static  corporateFile = UrlConfig._basePath + 'corporate/corporate-file';

  static supplierQuery=UrlConfig._basePath+'supplier/query';
  static supplierList=UrlConfig._basePath+'supplier/list';
  static supplierRegister=UrlConfig._basePath+'supplier/register';
  static supplierUpdate=UrlConfig._basePath+'supplier/update';
  static  supplierLogo = UrlConfig._basePath + 'supplier/download-logo/';
  static  supplierUploadLogo = UrlConfig._basePath + 'supplier/upload-logo';
  static  supplierAddPos = UrlConfig._basePath + 'supplier/add-pos';
  static  supplierDownloadPos = UrlConfig._basePath + 'supplier/download-pos';

  static categoryAllList=UrlConfig._basePath+'category/category-list';

  static  campaignSave = UrlConfig._basePath + 'campaign/save';
  static  campaignUpdate = UrlConfig._basePath + 'campaign/update';
  static  campaignQuery = UrlConfig._basePath + 'campaign/query';
  static  campaignCorporateList = UrlConfig._basePath + 'campaign/coporate/';
  static  campaignUploadImage= UrlConfig._basePath + 'campaign/upload-image';
  static  campaignDownloadImage= UrlConfig._basePath + 'campaign/download-image/';
  static  campaignAddCouponCode= UrlConfig._basePath + 'campaign/add-coupon';
  static  campaignGetCouponDetail = UrlConfig._basePath + 'campaign/coupon-detail';
  static  campaignDownloadCouponFile = UrlConfig._basePath + 'campaign/coupon-file';
  static  campaignList = UrlConfig._basePath + 'campaign/list';
  static  campaignTypesList = UrlConfig._basePath + 'campaign/list-types';

  static  cashbackQuery = UrlConfig._basePath + 'cashback/query';
  static  cashbackUpdate = UrlConfig._basePath + 'cashback/update';

  static transactionQueryEmailLog = UrlConfig._basePath + 'transaction/query/email-log';

  static  announcementRegister = UrlConfig._basePath + 'announcement/register';
  static  announcementUpdate = UrlConfig._basePath + 'announcement/update';
  static  announcementQuery = UrlConfig._basePath + 'announcement/query';
  static  announcementUploadImage = UrlConfig._basePath + 'announcement/upload-image';
  static  announcementDownloadImage = UrlConfig._basePath + 'announcement/download-image/';

  static  pushRegister = UrlConfig._basePath + 'push/register';
  static  pushQuery = UrlConfig._basePath + 'push/query';
  static  pushUpdate = UrlConfig._basePath + 'push/update';

  static  moduleList = UrlConfig._basePath + 'modules/list';

  static  surveyQuery = UrlConfig._serviceBasePath + 'survey/admin-query';
  static  surveyRegister = UrlConfig._serviceBasePath + 'survey/add';
  static  surveyUpdate = UrlConfig._serviceBasePath + 'survey/update';
  static  surveyTypeList = UrlConfig._serviceBasePath + 'survey/type-list';
  static  surveyReport = UrlConfig._serviceBasePath + 'survey-report/file';
}
