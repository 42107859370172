import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlConfig } from "../config/url";
import { map } from "rxjs";
import { ModulesListResponse } from "../model/response/modules.list.response";

@Injectable()
export class ModulesService {
  constructor(private http: HttpClient) {
  }

  list() {
    return this.http.get(UrlConfig.moduleList).pipe(
      map(m => {
        return new ModulesListResponse(m)
      })
    );
  }
}