import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoginService} from "../../service/login.service";
import {Router} from "@angular/router";
import {NzMessageService} from "ng-zorro-antd/message";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {


  validateForm!: FormGroup;
  showLoading = false;


  constructor(private fb: FormBuilder, private loginService: LoginService, private router: Router,private message: NzMessageService) {
    if(!loginService.isTokenExpired()){
      this.router.navigate(['users']);
    }
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [true]
    });
  }


  submitForm(): void {
    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(i)) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
    if (this.validateForm.valid) {
      this.showLoading = true;

      this.loginService.username = this.validateForm.get("userName")?.value;
      this.loginService.password = this.validateForm.get("password")?.value;

      this.loginService.otpLogin({
        username: this.loginService.username,
        password: this.loginService.password,
      }).subscribe((r) => {
        if (r) {
          this.showLoading = false;
          this.router.navigate(['otp']);
        }else{
          this.showLoading=false;
          this.message.error('Kullanıcı adı veya şifre hatalı');
        }
      },error => {
        this.showLoading=false;
        this.message.error('Kullanıcı adı veya şifre hatalı');
      })
    }

  }

}
