import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from "@angular/common/http";
import {Observable} from "rxjs";
import {LocalStorageService} from "../service/local.storage.service";
import {Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Injectable()
export class HttpXInterceptor implements HttpInterceptor {
    constructor(private localStorage: LocalStorageService, private router: Router, private notification: NzNotificationService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var token = this.localStorage.get('token');
        var expire = this.localStorage.get('expire');

        if (expire != null && Number(expire) < (new Date()).getTime()) {
            console.log(expire);
            this.localStorage.clearAll();
            this.router.navigate(['login']);

        } else if (token == null) {
            if(this.router.url != "/otp") {
                this.router.navigate(['login']);
            }
        } else {
            req = req.clone({
                url: req.url,
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'Accept-Language': `tr-tr`,
                }
            });
        }
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.body.code != undefined && event.body.code != 'OK') {
                        var messages = '<ul>';
                        event.body.messages.forEach((m: string) => {
                            messages += '<li>' + m + '</li>';
                        });
                        messages += '</ul>';
                        this.notification.error('Hata', messages);
                    }
                }
                return event;
            }));
    }
}
