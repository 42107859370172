import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NZ_I18N, tr_TR} from 'ng-zorro-antd/i18n';
import {registerLocaleData} from '@angular/common';
import tr from '@angular/common/locales/tr';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {IconsProviderModule} from './icons-provider.module';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzMenuModule} from 'ng-zorro-antd/menu';
import {NzBreadCrumbModule} from "ng-zorro-antd/breadcrumb";
import {PagesModule} from "./pages/pages.module";
import {AuthModule} from "./auth/auth.module";
import {LoginService} from "./service/login.service";
import {NgxMaskModule} from "ngx-mask";
import {LocalStorageService} from "./service/local.storage.service";
import {AuthorizeGuard} from "./service/authorize.guard.service";
import {UserService} from "./service/user.service";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {CorporateService} from "./service/corporate.service";
import {SupplierService} from "./service/supplier.service";
import {CampaignService} from "./service/campaign.service";
import { CashbackService } from './service/cashback.service';
import { EmailLogService } from './service/email-log.service';
import { OtpService } from './service/otp.service';
import { HelperService } from './service/helper.service';
import { AnnouncementService } from './service/announcement.service';
import { PushService } from './service/push.service';
import { ModulesService } from './service/modules.service';
import { SurveyService } from './service/survey.service';

registerLocaleData(tr);

@NgModule({
    declarations: [
        AppComponent],
    imports: [
        PagesModule,
        AuthModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        IconsProviderModule,
        NzLayoutModule,
        NzMenuModule,
        NzBreadCrumbModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [{
        provide: NZ_I18N,
        useValue: tr_TR
    }, LoginService, LocalStorageService, AuthorizeGuard, UserService, CorporateService, SupplierService, NzMessageService, NzNotificationService, CampaignService, CashbackService, EmailLogService, OtpService, HelperService, AnnouncementService, PushService, ModulesService, SurveyService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
