<div nz-row>
  <div nz-col style="display: flex; align-items: center; margin-left: 4px; margin-bottom: 16px;">
    <th style="text-align: center;">Firma Verileri</th>
    <button (click)="downloadCorporates()" nz-button nzShape= "circle" nzType= "primary" style="font-weight: bold; margin-left: 16px;">
      <i class="icon"
         nz-icon
         nzType="download"></i>
    </button>
  </div>
</div>

<nz-table #editRowTable [nzData]="listOfData" [nzLoading]="dataLoading" [nzPageSize]="10" nzBordered
          nzShowSizeChanger>
  <thead>
  <tr>
    <th>Firma Adı
    </th>
    <th>Yetkili</th>
    <th>Yetkili Telefon</th>
    <th>Yetkili Mail</th>
    <th>Durum</th>
    <th nzWidth="200px">İşlemler</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of editRowTable.data">
    <ng-container>
      <td>

        {{ data.name }}
      <td>
        {{data.contactName}}
      </td>
      <td>
        <div>{{ data.contactPhone|mask: '(000) 000 00 00' }}</div>
      </td>
      <td>{{ data.contactEmail }}</td>
      <td>
        <nz-tag [nzColor]="data.status?'#87d068':'#f50'">{{ data.status ? 'Aktif' : 'Pasif' }}</nz-tag>
      </td>

      <td>
        <button (click)="showModal(data)" nz-button nzShape= "circle" nzType= "primary" style="font-weight: bold; margin-left: 10px; margin-right: 10px">
          <i class="icon"
             nz-icon
             nzType="eye"></i>
        </button>
        <button (click)="showAddRegistrationModal(data)" nz-button nzShape="round" nzType="dashed">Sicil Tanımı</button>

      </td>

    </ng-container>
  </tr>

  </tbody>
</nz-table>

<div style="float: left">
  <button (click)="newCorporateModalShow()" nz-button nzShape="round"  nzType="primary">Üye Firma Ekle <i nz-icon nzType="plus"></i>
  </button>
</div>


<nz-modal (nzOnCancel)="modalCancel()" (nzOnOk)="modalOk()" [(nzVisible)]="corporateModalVisible" nzOkText="Kaydet">
  <ng-container *nzModalTitle>
    <span style="margin-right: 10px">Firma Bilgileri</span>
    <a (click)="startEdit(selectedCorporate)" *ngIf="!isEditing" style="font-weight: bold"><i
      class="icon" nz-icon
      nzType="edit"></i></a>
    <a (click)="cancelEdit(selectedCorporate)" *ngIf="isEditing" style="font-weight: bold"><i
      class="icon" nz-icon
      nzType="close"></i></a>
  </ng-container>

  <ng-container *nzModalContent>
    <ng-container *ngIf="!isEditing; else editTemplate">

      <nz-descriptions nzBordered nzSize="small">
        <nz-descriptions-item nzSpan="4" nzTitle="Logo"><img [src]="selectedCorporate.logo"
                                                             alt="{{selectedCorporate.shortName}} logo"
                                                             height="64"/>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Firma Adı">{{selectedCorporate.name}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Firma Kısa Adı">{{selectedCorporate.shortName}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Firma ID">{{selectedCorporate.id}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Vergi Kimlik No.">{{selectedCorporate.vkn}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Yetkili">{{selectedCorporate.contactName}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                              nzTitle=" Yetkili Telefon">{{selectedCorporate.contactPhone|mask: '(000) 000 00 00'}}</nz-descriptions-item>

        <nz-descriptions-item nzSpan="4"
                              nzTitle="Yetkili Mail">{{selectedCorporate.contactEmail}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Mail Uzantıları">
          <nz-tag *ngFor="let mail of selectedCorporate.mailDomains">{{mail}}</nz-tag>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                              nzTitle="Durum">{{ selectedCorporate.status ? 'Aktif' : 'Pasif' }}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Tanımlı Kampanyalar">
          <nz-select [ngModel]="selectedCorporateCampaignList"
            [nzMaxTagCount]="2"
            style="width: 100%;"
            [nzMaxTagPlaceholder]="attributeTagPlaceHolder" nzMode="multiple" nzPlaceHolder="Tanımlı Kampanya Yok">
            <nz-option disabled=""
              *ngFor="let option of campaignList"
              [nzLabel]="option.text"
              [nzValue]="option.id"
              [nzDisabled]="true"
            ></nz-option>
          </nz-select>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Tanımlı Modüller">
          <nz-select [ngModel]="selectedCorporate.modules"
            [nzMaxTagCount]="2"
            style="width: 100%;"
            [nzMaxTagPlaceholder]="attributeTagPlaceHolder" nzMode="multiple" nzPlaceHolder="Tanımlı Modül Yok">
            <nz-option disabled=""
              *ngFor="let option of modulesList"
              [nzLabel]="option.text"
              [nzValue]="option.id"
              [nzDisabled]="true"
            ></nz-option>
          </nz-select>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                              nzTitle="Oluşturulma Zamanı">{{selectedCorporate.createDate|date:'dd.MM.yyyy HH:mm'}}</nz-descriptions-item>

      </nz-descriptions>
    </ng-container>

  </ng-container>

</nz-modal>

<nz-modal (nzOnCancel)="newCorporateModalCancel()" (nzOnOk)="saveNewCorporate()"
          [(nzVisible)]="newCorporateModalVisible" nzOkText="Kaydet">
  <ng-container *nzModalTitle>
    <span style="margin-right: 10px">Firma Ekle</span>
  </ng-container>

  <ng-container *nzModalContent>
    <ng-container>

      <nz-descriptions nzBordered nzSize="small">
        <nz-descriptions-item nzSpan="4" nzTitle="Logo"><input (change)="onFileChanged($event)" accept="image/x-png,image/jpeg"
                                                               type="file"/>
        </nz-descriptions-item>

        <nz-descriptions-item nzSpan="4" nzTitle="Firma Adı"><input [(ngModel)]="newCorporate.name" nz-input
                                                                    type="text"/></nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Firma Kısa Adı"><input [(ngModel)]="newCorporate.shortName" nz-input
                                                                         type="text"/></nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Vergi Kimlik No."><input [(ngModel)]="newCorporate.vkn" nz-input
                                                                           type="text"/></nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                              nzTitle=" Yetkili Telefon"><input [(ngModel)]="newCorporate.contactPhone"
                                                                mask="(000) 000 00 00"
                                                                nz-input
                                                                type="tel"/></nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Mail Uzantıları">
          <nz-tag
            (nzOnClose)="handleClose(tag)"
            *ngFor="let tag of newCorporate.mailDomains; let i = index"
            nzMode="closeable"
          >
            {{ sliceTagName(tag) }}
          </nz-tag>
          <nz-tag (click)="showInput()" *ngIf="!mailInputVisible" class="editable-tag" nzNoAnimation>
            <i nz-icon nzType="plus"></i>
            Ekle
          </nz-tag>
          <input
            #inputElement
            (blur)="handleInputConfirm(false)"
            (keydown.enter)="handleInputConfirm(false)"
            *ngIf="mailInputVisible"
            [(ngModel)]="mailInputValue"
            nz-input
            nzSize="small"
            style="width: 78px;"
            type="text"
          />
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="Tanımlı Kampanyalar">
          <nz-select [(ngModel)]="newCorporate.campaigns"
                    [nzMaxTagCount]="1"
                    style="width: 100%;"
                    [nzMaxTagPlaceholder]="attributeTagPlaceHolder" nzMode="multiple" nzPlaceHolder="Kampanya Tanımla">
            <nz-option
              *ngFor="let option of campaignList"
              [nzLabel]="option.text"
              [nzValue]="option.id"
            ></nz-option>
          </nz-select>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="Modüller">
          <nz-select [(ngModel)]="newCorporate.modules"
                    [nzMaxTagCount]="1"
                    style="width: 100%;"
                    [nzMaxTagPlaceholder]="attributeTagPlaceHolder" nzMode="multiple" nzPlaceHolder="Modül Tanımla">
            <nz-option
              *ngFor="let option of modulesList"
              [nzLabel]="option.text"
              [nzValue]="option.id"
            ></nz-option>
          </nz-select>
        </nz-descriptions-item>

      </nz-descriptions>
    </ng-container>

  </ng-container>

</nz-modal>
<ng-template #attributeTagPlaceHolder let-selectedList> +{{ selectedList.length }}  </ng-template>

<ng-template #editTemplate>
  <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">

    <nz-descriptions nzBordered nzSize="small">
      <nz-descriptions-item nzSpan="4" nzTitle="Logo"><input (change)="onFileChanged($event)" accept="image/x-png,image/jpeg"
                                                             type="file"/>
      </nz-descriptions-item>

      <nz-descriptions-item nzSpan="4" nzTitle="Firma Adı"><input [(ngModel)]="selectedCorporate.name" nz-input
                                                                  type="text"/></nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Firma Kısa Adı"><input [(ngModel)]="selectedCorporate.shortName"
                                                                       nz-input
                                                                       type="text"/></nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Firma ID">{{selectedCorporate.id}}</nz-descriptions-item>

      <nz-descriptions-item nzSpan="4" nzTitle="Vergi Kimlik No."><input [(ngModel)]="selectedCorporate.vkn" nz-input
                                                                         type="text"/></nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Yetkili"><input [(ngModel)]="selectedCorporate.contactName" nz-input
                                                                readOnly type="text"/></nz-descriptions-item>
      <nz-descriptions-item nzSpan="4"
                            nzTitle=" Yetkili Telefon"><input [(ngModel)]="selectedCorporate.contactPhone"
                                                              mask="(000) 000 00 00"
                                                              nz-input
                                                              type="tel"/></nz-descriptions-item>

      <nz-descriptions-item nzSpan="4"
                            nzTitle="Yetkili Mail"><input [(ngModel)]="selectedCorporate.contactEmail" nz-input
                                                          readOnly type="text"/></nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Mail Uzantıları">
        <nz-tag
          (nzOnClose)="handleUpdateClose(tag)"
          *ngFor="let tag of selectedCorporate.mailDomains; let i = index"
          nzMode="closeable"
        >
          {{ sliceTagName(tag) }}
        </nz-tag>
        <nz-tag (click)="showInput()" *ngIf="!mailInputVisible" class="editable-tag" nzNoAnimation>
          <i nz-icon nzType="plus"></i>
          Ekle
        </nz-tag>
        <input
          #inputElement
          (blur)="handleInputConfirm(true)"
          (keydown.enter)="handleInputConfirm(true)"
          *ngIf="mailInputVisible"
          [(ngModel)]="mailInputValue"
          nz-input
          nzSize="small"
          style="width: 78px;"
          type="text"
        />
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Durum">
        <nz-select [(ngModel)]="selectedCorporate.status">
          <nz-option [nzLabel]="'Aktif'" [nzValue]="true"></nz-option>
          <nz-option [nzLabel]="'Pasif'" [nzValue]="false"></nz-option>
        </nz-select>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="5"
                            nzTitle="Tanımlı Kampanyalar">
        <nz-select [(ngModel)]="selectedCorporateCampaignList"
                   [nzMaxTagCount]="2"
                   style="width: 100%;"
                   [nzMaxTagPlaceholder]="attributeTagPlaceHolder" nzMode="multiple" nzPlaceHolder="Kampanya Tanımla">
          <nz-option
                  *ngFor="let option of campaignList"
                  [nzLabel]="option.text"
                  [nzValue]="option.id"
          ></nz-option>
        </nz-select>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="5"
                            nzTitle="Tanımlı Modüller">
        <nz-select [(ngModel)]="selectedCorporate.modules"
                   [nzMaxTagCount]="2"
                   style="width: 100%;"
                   [nzMaxTagPlaceholder]="attributeTagPlaceHolder" nzMode="multiple" nzPlaceHolder="Modül Tanımla">
          <nz-option
                  *ngFor="let option of modulesList"
                  [nzLabel]="option.text"
                  [nzValue]="option.id"
          ></nz-option>
        </nz-select>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="4"
                            nzTitle="Oluşturulma Zamanı">{{selectedCorporate.createDate|date:'dd.MM.yyyy HH:mm'}}</nz-descriptions-item>


    </nz-descriptions>


  </nz-spin>
</ng-template>

<nz-modal (nzOnCancel)="addRegistrationModalCancel()" (nzOnOk)="addRegistrationModalOk()" 
          [(nzVisible)]="addRegistrationModalVisible" nzOkText="Kaydet">
  
  <ng-container *nzModalTitle>
    <span style="margin-right: 10px; font-weight: bold">
      {{selectedCorporate.shortName}} Sicil Numarası {{!showRegistrationDetail ?"Ekle" : "Tanımları"}}</span>
    <a (click)="showRegistrationDetail=true" *ngIf="!showRegistrationDetail" style="font-weight: bold"><i
            class="icon" nz-icon
            nzType="file-search"></i></a>
    <a (click)="showRegistrationDetail=false" *ngIf="showRegistrationDetail" style="font-weight: bold"><i
            class="icon" nz-icon
            nzType="close"></i></a>
  </ng-container>

  
  <ng-container *nzModalContent>
  <ng-container *ngIf="!showRegistrationDetail; else registrationDetailTemplate">
  <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin..."> 
    <ng-container>
      <nz-descriptions nzBordered nzSize="small">

        <nz-descriptions-item nzSpan="5" nzTitle="Sicil Numarası">
          <input type="file" (change)="getRegistrationFile($event)" class="file"
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Tanımlama Tipi">
          <label nz-checkbox [(ngModel)]="isNotDefaultRegistration">Ad-Soyad İçerir</label>
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="isNotDefaultRegistration" nzSpan="4" nzTitle="Tamımlama Stili">
          <nz-select [(ngModel)]="registrationStyle">
            <nz-option nzValue="atLast" nzLabel="Ad-Soyad Sonda"></nz-option>
            <nz-option nzValue="atFirst" nzLabel="Ad-Soyad Başta"></nz-option>
          </nz-select>
        </nz-descriptions-item>

      </nz-descriptions>
    </ng-container>
    </nz-spin>
  </ng-container>
  </ng-container>
</nz-modal>

<ng-template #registrationDetailTemplate>
  <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">
    <nz-table #detailTable
              [nzFrontPagination]="false"
              [nzShowPagination]="false"
              nzBordered>

      <nz-descriptions nzBordered nzSize="small">

        <nz-descriptions-item nzSpan="5" nzTitle="Sicil Numarası Tanımları">
          <button (click)="downloadRegistrationFile()">Download File</button>
        </nz-descriptions-item>

      </nz-descriptions>
    </nz-table>
  </nz-spin>
</ng-template>
