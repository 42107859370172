import { Component, OnInit } from '@angular/core';
import { IntValue } from "../../model/int.value";
import { CorporateService } from "../../service/corporate.service";
import { SupplierService } from "../../service/supplier.service";
import { CampaignSaveRequest } from "../../model/request/campaign.save.request";
import { CampaignService } from "../../service/campaign.service";
import { Campaign } from "../../model/campaign";
import { lastValueFrom } from "rxjs";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { StringValue } from "../../model/string.value";
import { CampaignUpdateRequest } from "../../model/request/campaign.update.request";
import { CouponDetail } from 'src/app/model/coupon.detail';
import { HelperService } from 'src/app/service/helper.service';

@Component({
    selector: 'app-campaign',
    templateUrl: './campaign.component.html',
    styleUrls: ['./campaign.component.less']
})
export class CampaignComponent implements OnInit {
    tableDataLoading = false;
    addCampaignModalVisible = false;
    viewModalVisible = false;

    corporateList!: IntValue[];
    selectedCampaignCorporateList!: number[];
    supplierList!: IntValue[];
    campaignList!: Campaign[];
    selectedCampaign!: Campaign;
    tempSelectedCampaign!: Campaign;
    newCampaign!: CampaignSaveRequest;
    locationOptions = [
        { label: 'Web', value: 'web', checked: false },
        { label: 'Mağaza', value: 'store', checked: false },
    ];
    campaignType!: string;
    webIsChecked = true;
    selectedImage?: File;
    editing = false;
    isUpdating = false;
    categories: StringValue[] = [];
    addCouponModalVisible: boolean = false;
    couponFile?: File;
    showCouponDetail = false;
    couponDetailList?: CouponDetail[];
    searchValue = '';
    searchType = 'supplier';
    visible = false;
    listOfDisplayData: Campaign[] = [];
    hidePassive = true;
    hideActive = false;
    filter? = true;
    notificationIsEnabled = false;
    maxSizeInBytes = 200 * 1024; // 200 KB
    typeList!: StringValue[];

    constructor(private corporateService: CorporateService, private supplierService: SupplierService,
        private campaignService: CampaignService, private notification: NzNotificationService, private helperService: HelperService) {
    }

    ngOnInit(): void {
        this.query();
        this.corporateService.list().subscribe(c => {
            this.corporateList = c.list;
        });

        this.campaignService.listTypes().subscribe(c => {
            this.typeList = c.list;
        });
    }

    query() {
        this.tableDataLoading = true;
        this.campaignService.query().subscribe(c => {
            this.campaignList = c.list;
            this.listOfDisplayData = [...this.campaignList.filter((item: Campaign) => {
                return item.status != null && (item.status == this.filter);
            })];
            this.tableDataLoading = false;
        });
    }

    showAddCampaignModal() {
        this.notificationIsEnabled = false;
        this.selectedImage = undefined;
        this.campaignType = 'cashback';
        this.newCampaign = new CampaignSaveRequest();
        this.newCampaign.status = true;
        this.newCampaign.locations = ['web', 'store'];
        this.newCampaign.startDate = new Date();
        this.newCampaign.endDate = new Date();
        this.newCampaign.endDate.setFullYear(this.newCampaign.endDate.getFullYear() + 1);
        this.newCampaign.locations = this.locationOptions.map(m => {
            m.checked = false;
            this.webIsChecked = false;
            return m.value;
        });
        this.newCampaign.corporates = this.corporateList.map(m => m.id);
        this.addCampaignModalVisible = true;
        this.supplierService.list().subscribe(s => {
            this.supplierList = s.list;
        });
    }

    addCampaignModalCancel() {
        console.log(this.campaignType);
        this.addCampaignModalVisible = false;
    }

    addCampaignModalOk() {
        this.isUpdating=true;
        this.newCampaign.campaignType = this.campaignType;
        this.newCampaign.notificationEnabled = this.notificationIsEnabled;
        if(this.notificationIsEnabled) {
            this.newCampaign.notificationDate.setMilliseconds(0);
            this.newCampaign.notificationDate = this.helperService.dateTimezone(this.newCampaign.notificationDate);
        }
        this.newCampaign.endDate = this.helperService.dateTimezone(this.newCampaign.endDate);
        this.newCampaign.startDate = this.helperService.dateTimezone(this.newCampaign.startDate);
        if (this.selectedImage != null && this.selectedImage != undefined) {
            this.campaignService.save(this.newCampaign).subscribe(s => {
                if (s.isOk()) {
                    this.campaignService.uploadLogo(this.selectedImage!, s.id).subscribe(ret => {
                        this.hidePassive=true;
                        this.hideActive=false;
                        this.filter=true;
                        this.query();
                        this.addCampaignModalVisible = false;
                    });
                }
                this.isUpdating=false;
            })
        } else {
            this.isUpdating=false;
            this.notification.error('Hata', 'Gerçerli formatta görsel yükleyiniz. (png)');
        }

    }


    locationChange() {
        this.webIsChecked = false;
        this.newCampaign.locations = this.locationOptions.filter(f => f.checked).map(m => {
            if (m.value == 'web') {
                this.webIsChecked = true;
            }
            return m.value;
        });
    }

    locationChangeUpdate(event: any) {
        this.selectedCampaign.locations = event.filter((f: { checked: boolean }) => f.checked).map((m: { value: string }) => {
            if (m.value == 'web') {
                this.webIsChecked = true;
            }
            return m;
        });

    }

    campaignTypeBody() {
        return this.typeList.find(sv => sv.value === this.selectedCampaign.campaignType)?.text;
    }

    campaignRateTitle() {
        if (this.viewModalVisible) {
            return this.typeList.find(sv => sv.value === this.selectedCampaign.campaignType)?.text + " Oranı/Tutarı";
        } else {
            return this.typeList.find(sv => sv.value === this.campaignType)?.text + " Oranı/Tutarı";
        }
    }

    onFileChanged(event: any) {
        let file = event.target.files[0];
        if (file != undefined && (file.type == 'image/png' || file.type == 'image/jpeg')) {
            this.selectedImage = file;
        } else {
            this.notification.error('Hata', 'Gerçerli formatta görsel yükleyiniz.(png)');
        }
    }

    showViewModal(data: Campaign) {
        this.selectedImage = undefined;
        this.selectedCampaign = data;
        this.notificationIsEnabled = 
            this.selectedCampaign.notificationContent != null || this.selectedCampaign.notificationTitle != null || this.selectedCampaign.notificationDate != null;
        this.tempSelectedCampaign = Object.assign({}, data);
        this.campaignService.campaignCorporates(this.selectedCampaign.id).subscribe(s => {
            if (s.isOk()) {
                this.selectedCampaign.corporates = s.list;
                lastValueFrom(this.campaignService.getImage(data.id)).then(v => {
                    if (v.size != 0) {
                        const reader = new FileReader();
                        reader.onload = (e) => this.selectedCampaign.logo = e.target!.result;
                        reader.readAsDataURL(v);
                    }
                    this.selectedCampaignCorporateList = this.selectedCampaign.corporates.map(m => m.id);
                        this.viewModalVisible = true;
                        this.viewModalVisible = true;
                    });
                    this.viewModalVisible = true;
                    }});

                }

    startEdit() {
        this.editing = true;
        this.selectedCampaign.locations.forEach(f => {
            let at = this.locationOptions.filter(fil => fil.value == f.value)[0];
            at.checked = true;
            if (at.value == 'web') {
                this.webIsChecked = true;
            }
        });

    }

    categoryList() {
        return this.supplierService.categoryList().subscribe(s => {
            this.categories = s.list;
        });
    }

    cancelEdit() {
        this.editing = false;
    }

    viewModalCancel() {
        this.viewModalVisible = false;
        Object.assign(this.selectedCampaign, this.tempSelectedCampaign);
        this.editing = false;
    }

    viewModalOk() {
        this.isUpdating = true;
        var req = new CampaignUpdateRequest();
        req.campaignId = this.selectedCampaign.id;
        req.status = this.selectedCampaign.status;
        req.commission = this.selectedCampaign.commission;
        req.cashBackRatio = this.selectedCampaign.cashBackRatio;
        req.description = this.selectedCampaign.description;
        req.campaignType = this.selectedCampaign.campaignType;
        req.title = this.selectedCampaign.title;
        req.startDate = this.helperService.dateTimezone(this.selectedCampaign.startDate);
        req.endDate = this.helperService.dateTimezone(this.selectedCampaign.endDate);
        req.webSite = this.selectedCampaign.webSite;
        req.couponLimit = this.selectedCampaign.couponLimit;
        req.conditions = this.selectedCampaign.conditions;
        if (this.selectedCampaign.locations != null) {
            req.locations = this.selectedCampaign.locations.map(m => m.value);
        }
        req.corporates = this.selectedCampaignCorporateList;
        req.notificationEnabled = this.notificationIsEnabled;
        if(this.notificationIsEnabled) {
            req.notificationTitle = this.selectedCampaign.notificationTitle;
            req.notificationContent = this.selectedCampaign.notificationContent;
            req.notificationDate = this.helperService.dateTimezone(this.selectedCampaign.notificationDate);
        }

        if(this.selectedImage != undefined) {
            if(this.selectedImage.size <= this.maxSizeInBytes) {
                this.updateCampaign(req);
            }else {
                this.isUpdating = false;
                this.notification.error('Hata', 'Yüklediğiniz görsel ' + Math.floor(this.maxSizeInBytes / 1000) + ' kb altında olmalıdır.');
            }
        } else {
            this.updateCampaign(req);
        }
        
        

    }

    updateCampaign(req:CampaignUpdateRequest) {
        this.campaignService.update(req).subscribe(s => {
            if (s.isOk()) {
                if (this.selectedImage != null && this.selectedImage != undefined) {
                    this.campaignService.uploadLogo(this.selectedImage, s.id).subscribe(ret => {
                    });
                }
                this.hideActive = false;
                this.hidePassive = true;
                this.filter = true;
                this.editing = false;
                this.viewModalVisible = false;
                this.query();
            } else {
                Object.assign(this.selectedCampaign, this.tempSelectedCampaign);
            }
            this.isUpdating=false;

        })
    }

    isWeb() {
        return this.selectedCampaign.locations.map(m => m.value).indexOf('web') != -1;
    }

    showAddCouponModal(campaign: Campaign) {
        this.showCouponDetail = false;
        this.couponFile = undefined;
        this.selectedCampaign = campaign;
        this.corporateService.list().subscribe(c => {
            this.corporateList = c.list;
            this.addCouponModalVisible = true;
        });
    }

    addCouponModalCancel() {
        this.addCouponModalVisible = false;
    }

    addCouponModalOk() {
        if (!this.showCouponDetail) {
            this.isUpdating = true;
            if (this.couponFile != undefined) {
                this.campaignService.addCouponCode(this.couponFile, this.selectedCampaign.id).subscribe(s => {
                    if (s.isOk()) {
                        this.isUpdating = false;
                        this.addCouponModalVisible = false;
                        this.notification.success('', 'İndirim kodları tanımlandı.')
                    }
                });
            } else {
                this.isUpdating = false;
                this.notification.error('Hata', 'Tüm alanları eksiksiz doldurun.');
            }
        } else {
            this.addCouponModalVisible = false;
        }
    }

    getCouponFile(event: any) {
        let file = event.target.files[0];
        this.couponFile = file;
    }

    startCouponDetail() {
        /* this.couponDetailList = undefined;
        this.campaignService.getCouponDetail(this.selectedCampaign.id).subscribe(s => {
            if (s.isOk()) {
                if (s.detailList[0].totalCodeCount != undefined) {
                    this.couponDetailList = s.detailList;
                }
                this.showCouponDetail = true;
            }
        }); */
        this.showCouponDetail = true;
    }

    cancelCouponDetail() {
        this.showCouponDetail = false;
    }

    downloadCouponFile() {
        this.isUpdating = true;
        lastValueFrom(this.campaignService.getCouponFile(this.selectedCampaign.id)).then(v => {
            if (v.size != 0) {
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(v);
                link.download = this.selectedCampaign.supplier + "-" + this.selectedCampaign.id + "-coupon.xlsx";
                link.click();
                this.addCouponModalVisible = false;
                this.isUpdating = false;
            }
        });
    }

    reset(): void {
        this.searchValue = '';
        this.listOfDisplayData = this.campaignList;
    }

    search(): void {
        this.visible = false;
        this.listOfDisplayData = this.campaignList.filter((item: Campaign) => {

        switch (this.searchType) {
            case 'supplier': return item.supplier != null && (item.supplier.toLowerCase().toString().replace("i̇", "i")).indexOf(this.searchValue.toLowerCase()) !== -1;
            case 'campaignType': return item.campaignType != null && (item.campaignType.toString()).indexOf(this.searchValue) !== -1;
            case 'campaignTitle': return item.title != null && (item.title.toLowerCase().toString().replace("i̇", "i")).indexOf(this.searchValue.toLowerCase()) !== -1;
            default: return item.campaignType != null && (item.campaignType.toString()).indexOf(this.searchValue) !== -1;
        }
        }).filter((item: Campaign) => {
            if(this.filter != undefined) {
                return item.status != null && (item.status == this.filter);
            }else {
                if(this.hideActive && this.hidePassive) {
                    return null;
                }else {
                    return item;
                }
                
            }
            
        });
    }

    searchTypeChange() {
        this.searchValue = '';
        switch (this.searchType) {
            case 'status': this.searchValue = 'active'; break;
            case 'campaignType': this.searchValue = 'type'; break;
        }
    }


    hidePassiveCampaign() {
        if(this.hidePassive && this.hideActive) {
            this.filter = undefined;
        }else if (!this.hidePassive && !this.hideActive) {
            this.filter = undefined;
        }else if (this.hidePassive && !this.hideActive) {
            this.filter = true;
        }else {
            this.filter = false;
        }
        
        this.search();
    }

    hideActiveCampaign() {
        if(this.hidePassive && this.hideActive) {
            this.filter = undefined;
        }else if (!this.hidePassive && !this.hideActive) {
            this.filter = undefined;
        }else if (!this.hidePassive && this.hideActive) {
            this.filter = false;
        }else {
            this.filter = true;
        }

        this.search();
    }

    addDashes(description: string): string {
        if(description != null && description != "") {
            if(description.includes('\n')) {
                const lines = description.split('\n');
                return lines.map(line =>  '• ' + line).join('\n');
            }else {
                return '• ' + description;
            }
        }else {
            return "";
        }
        
      }
}
