import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthorizeGuard} from "./service/authorize.guard.service";

const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import("./pages/pages.module").then((t) => t.PagesModule),
    canActivateChild: [AuthorizeGuard],canActivate:[AuthorizeGuard]
  },
  {path: 'auth', loadChildren: () => import("./auth/auth.module").then((t) => t.AuthModule)},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes ,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
