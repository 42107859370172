import { Injectable, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd/notification";

@Injectable()
export class HelperService implements OnInit {
    maxSizeInBytes = 200 * 1024; // 200 KB

    constructor(private notification: NzNotificationService) {}

    ngOnInit(): void {}

    dateTimezone(date: Date): Date {
        var newDate = new Date(date);
        var offsetHours = newDate.getTimezoneOffset() / 60;
        newDate.setHours(newDate.getHours() - offsetHours);
        return newDate;
    }

    checkImageSize(image: File | undefined) {
        if (image != undefined) {
          if (image.size > this.maxSizeInBytes) {
            this.notification.error('Hata', 'Yüklediğiniz görsel ' + Math.floor(this.maxSizeInBytes / 1000) + ' kb altında olmalıdır.');
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
}