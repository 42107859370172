<div nz-row style="margin: 20px 5px 20px 0">
    <div nz-col>
        <nz-input-group nzCompact>
            <nz-select [(ngModel)]="searchType" style="width:150px" (ngModelChange)="searchTypeChange()">
                <nz-option [nzLabel]="'Durum'" [nzValue]="'status'"></nz-option>
                <nz-option [nzLabel]="'Tedarikçi'" [nzValue]="'supplier'"></nz-option>
            </nz-select>
            <nz-select [(ngModel)]="searchValue" style="width: 150px" *ngIf="searchType=='status'">
                <nz-option [nzLabel]="'Beklemede'" [nzValue]="1"></nz-option>
                <nz-option [nzLabel]="'Pasif'" [nzValue]="2"></nz-option>
                <nz-option [nzLabel]="'Gönderildi'" [nzValue]="3"></nz-option>
            </nz-select>
            <input type="text" nz-input [(ngModel)]="searchValue" style="width:50%;"  *ngIf="searchType!=='status'"/>
        </nz-input-group>
    </div>
    <nz-date-picker style="margin: 0 10px 0 30px" nzPlaceHolder="Başlangıç" nzFormat="dd.MM.yyyy" [(ngModel)]="startDate" (ngModelChange)="startDateChange()"></nz-date-picker>
    <nz-date-picker style="margin-right: 30px" nzPlaceHolder="Bitiş" nzFormat="dd.MM.yyyy" [(ngModel)]="endDate" (ngModelChange)="endDateChange()"></nz-date-picker>
    <div nz-col nzSpan="3">
        <button nz-button  nzType="primary" (click)="search()">Ara</button>
        <button nz-button  nzType="default" style="margin-left: 5px" (click)="reset()">Sıfırla</button>
    </div>
</div>

<nz-table #cashbackTable [nzData] = "listOfDisplayData" [nzLoading]="tableDataLoading" nzPaginationType="small"
[nzPageSize]="10" nzBordered nzShowSizeChanger>
    <thead>
        <tr>
            <th>ID</th>
            <th>Harcama Tarihi</th>
            <th>Bekleme</th>
            <th>Cashback Tarihi</th>
            <th>Kullanıcı ID</th>
            <th>Tedarikçi</th>
            <th>Açıklama</th>
            <th>Merchant ID</th>
            <th>Tutar</th>
            <th>Cashback Oranı</th>
            <th>Durum</th>
            <th>İşlemler</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of cashbackTable.data">
            <ng-container>
                <td>{{data.id}}</td>
                <td>
                    <div>{{data.transactionDate|date:'dd.MM.yyyy HH:mm'}}</div>
                </td>
                <td>{{data.waitingPeriod}}</td>
                <td [ngSwitch]="data.status">
                    <div *ngSwitchCase="1">{{data.expectedCashbackDate|date:'dd.MM.yyyy HH:mm'}}</div>
                    <div *ngSwitchCase="2">{{data.expectedCashbackDate|date:'dd.MM.yyyy HH:mm'}}</div>
                    <div *ngSwitchCase="3">{{data.completedCashbackDate|date:'dd.MM.yyyy HH:mm'}}</div>
                </td>
                <td>{{data.userId}}</td>
                <td>{{data.supplier}}</td>
                <td>{{data.description}}</td>
                <td>{{data.merchantId}}</td>
                <td width="8%">{{data.amount}} TL</td>
                <td>%{{data.cashbackRatio}}</td>
                <td text-align="center" [ngSwitch]="data.status">
                    <nz-tag  *ngSwitchCase="1" [nzColor]="'#66ffdb'">Beklemede</nz-tag>
                    <nz-tag *ngSwitchCase="2" [nzColor]="'#ff2432'">Pasif</nz-tag>
                    <nz-tag *ngSwitchCase="3" [nzColor]="'#87d068'">Gönderildi</nz-tag>
                </td>
                <td width="16%" [ngSwitch]="data.status">
                    <div *ngSwitchCase="1" nz-row>
                        <button style="margin-right: 10px" (click)="showConfirmModal(data)" nz-button >Pasife Al</button>
                        <button (click)="approveModalView(data)" nzType= "primary" nz-button >Onayla</button>
                    </div>
                    <button *ngSwitchCase="2" (click)="showConfirmModal(data)" nz-button >Aktif Et</button>
                </td>
            </ng-container>
        </tr>
    </tbody>
</nz-table>


<nz-modal (nzOnCancel)="confirmModalCancel()" (nzOnOk)="confirmModalOk()" [(nzVisible)]="confirmModalVisible" nzOkText="Kaydet">

    <ng-container *nzModalTitle>
        <span style="margin-right:10px">Cashback'i {{selectedCashback.status == 1 ? "Pasife Al" : "Aktif Et" }}</span>
    </ng-container>
    

    <ng-container *nzModalContent>
        <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">
            <span style="margin-left:20px">Güncellemelerinizi kaydetmek istiyor musunuz?</span>
        </nz-spin>
    </ng-container>
</nz-modal>


<nz-modal (nzOnCancel)="approveModalCancel()" (nzOnOk)="approveModalOk()" [(nzVisible)]="approveModalVisible" nzOkText="Kaydet">

    <ng-container *nzModalTitle>
        <span style="margin-right:10px">Cashback'i Onayla</span>
    </ng-container>
    

    <ng-container *nzModalContent>
        <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">
            <span style="margin-left:20px">Seçilen cashback'i onaylamak istiyor musunuz?</span>
        </nz-spin>
    </ng-container>
</nz-modal>
    