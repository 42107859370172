<nz-table #editRowTable [nzData]="listOfDisplayData" [nzLoading]="tableDataLoading" [nzPageSize]="10" nzBordered
          nzShowSizeChanger>
  <thead>
    <tr>
        <th>ID</th>
        <th>Tarih</th>
        <th>Domain</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of editRowTable.data">
        <ng-container>
        <td>{{data.id}}</td>
        <td>{{data.createDate|date:'dd.MM.yyyy HH:mm'}}</td>
        <td>{{data.domain}}</td>
        </ng-container>
    </tr>
  </tbody>
</nz-table>