<nz-table #campaignTable [nzData]="announcementList" [nzLoading]="tableDataLoading" nzPaginationType="small" [nzPageSize]="10" nzBordered
            nzShowSizeChanger>
    <thead>
        <tr>
        <th nzWidth="8%">Id</th>
        <th nzWidth="23%">Başlık</th>
        <th nzWidth="23%">İçerik</th>
        <th nzWidth="10%">Firma</th>
        <th nzWidth="15%">Gönderilecek Tarih</th>
        <th nzWidth="10%">Durum</th>
        <th nzWidth="11%">İşlemler</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of campaignTable.data">
        <ng-container>
            <td>{{data.id}}</td>
            <td>{{data.title}}</td>
            <td>{{data.content}}</td>
            <td>{{data.corporateName}}</td>
            <td>
                <div>{{data.shareDate|date:'dd.MM.yyyy HH:mm'}}</div>
            </td>
            <td text-align="center" [ngSwitch]="data.status">
                <nz-tag  *ngSwitchCase="1" [nzColor]="'#ffb475'">Beklemede</nz-tag>
                <nz-tag *ngSwitchCase="2" [nzColor]="'#ff2432'">Pasif</nz-tag>
                <nz-tag *ngSwitchCase="3" [nzColor]="'#87d068'">Yayında</nz-tag>
            </td>
            <td>
              <button (click)="showViewModal(data)" nz-button nzShape= "circle" nzType= "primary" style="font-weight: bold; margin-left: 10px; margin-right: 10px">
                  <i class="icon" nz-icon nzType="eye"></i>
              </button>
              <!-- <button (click)="null" nz-button nzShape= "circle" 
                  style="font-weight: bold; margin-left: 10px; margin-right: 10px; background-color: #e9e8eb;">
                <i class="icon" nz-icon nzType="file-text"></i>
              </button> -->
            </td>
        </ng-container>
        </tr>
    </tbody>
  </nz-table>
  
<button (click)="showAddAnnouncementModal()" nz-button nzShape="round" nzType="primary" style="margin-top: 10px">Duyuru Ekle<i nz-icon nzType="plus"></i></button>

<nz-modal (nzOnCancel)="addAnnouncementModalCancel()" (nzOnOk)="addAnnouncementModalOk()"
          [(nzVisible)]="addAnnouncementModalVisible" nzOkText="Kaydet">

  <ng-container *nzModalTitle>
    <span style="margin-right: 10px; font-weight: bold">Duyuru Ekle</span>
  </ng-container>

  <ng-container *nzModalContent>
  <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">
      <nz-descriptions nzBordered nzSize="small">
        <nz-descriptions-item nzSpan="5" nzTitle="Başlık*">
          <input [(ngModel)]="newAnnouncement.title" nz-input type="text">
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="İçerik*" color="red">
            <textarea [(ngModel)]="newAnnouncement.content" nz-input rows="4"></textarea>
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Duyuru Görseli">
            <input (change)="onFileChanged($event)" accept="image/x-png,image/jpeg" type="file">
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="5" nzTitle="Paylaşım Tarihi*">
          <nz-row>
            <nz-date-picker [(ngModel)]="newAnnouncement.shareDate" [nzShowToday]="false" nzMode="date" (ngModelChange)="onDateChanged($event)" [nzFormat]="'dd.MM.yyyy'"></nz-date-picker>
            <nz-time-picker [nzDisabledHours]="disabledHours" [nzDisabledMinutes]="disabledMinutes" [nzMinuteStep]="30" [nzNowText]="'Şimdi'" [nzOkText]="'Bitti'" [(ngModel)]="newAnnouncement.shareDate" nzFormat="HH:mm"></nz-time-picker>
          </nz-row>
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="role==='ADMIN'" nzSpan="5"
                              nzTitle="Firma">
          <nz-select [(ngModel)]="newAnnouncement.corporate" nzShowSearch nzPlaceHolder="Firma Ekle" style="width: 60%;">
            <nz-option
              *ngFor="let option of corporateList"
              [nzLabel]="option.text"
              [nzValue]="option.id"
            ></nz-option>
          </nz-select>
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-spin>
  </ng-container>

</nz-modal>
<ng-template #attributeTagPlaceHolder let-selectedList> +{{ selectedList.length }}  </ng-template>

<nz-modal [nzWidth]="600" (nzOnCancel)="viewModalCancel()" (nzOnOk)="viewModalOk()"  nzOkText="Kaydet" [(nzVisible)]="viewModalVisible" >
  <ng-container *nzModalTitle>
    <span style="margin-right: 10px">{{selectedAnnouncement.title.substring(0,32)}} </span>
    <a (click)="startEdit()" *ngIf="!editing" style="font-weight: bold"><i
            class="icon" nz-icon
            nzType="edit"></i></a>
    <a (click)="cancelEdit()" *ngIf="editing" style="font-weight: bold"><i
            class="icon" nz-icon
            nzType="close"></i></a>
  </ng-container>

  <ng-container *nzModalContent>
    <ng-container *ngIf="!editing; else editTemplate">

      <nz-descriptions nzBordered nzSize="small">
        <nz-descriptions-item nzSpan="4" nzTitle="ID">{{selectedAnnouncement.id}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Başlık">{{selectedAnnouncement.title}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="İçerik">{{selectedAnnouncement.content}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Duyuru Görseli">
          <img [src]="selectedAnnouncement.picture" alt="Görsel bulunamadı." height="64" width="256">
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Firma">{{selectedAnnouncement.corporateName}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Gönderilecek Tarih">{{selectedAnnouncement.shareDate|date:'dd.MM.yyyy HH:mm:ss'}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Durum">
          {{selectedAnnouncement.status == 1 ? 'Beklemede' : selectedAnnouncement.status == 2 ? 'Pasif' : selectedAnnouncement.status == 3 ? 'Yayında' : ''}}
        </nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" nzTitle="Oluşturulma Zamanı">{{selectedAnnouncement.createDate|date:'dd.MM.yyyy HH:mm:ss'}}</nz-descriptions-item>
      </nz-descriptions>
    </ng-container>
  </ng-container>
</nz-modal>

<ng-template #editTemplate>
  <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">

    <nz-descriptions nzBordered nzSize="small">
      <nz-descriptions-item nzSpan="4" nzTitle="ID">{{selectedAnnouncement.id}}</nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Başlık"><input [(ngModel)]="selectedAnnouncement.title" nz-input type="text"></nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="İçerik"><input [(ngModel)]="selectedAnnouncement.content" nz-input type="text"></nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Duyuru Görseli">
        <input (change)="onFileChanged($event)" accept="image/x-png,image/jpeg" type="file">
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Firma">{{selectedAnnouncement.corporateName}}</nz-descriptions-item>
      <nz-descriptions-item nzSpan="5" nzTitle="Paylaşılacak Tarih">
        <nz-row>
          <nz-date-picker [(ngModel)]="selectedAnnouncement.shareDate" [nzShowToday]="false" nzMode="date" 
                          (ngModelChange)="onDateChanged($event)" [nzFormat]="'dd.MM.yyyy'">
          </nz-date-picker>
          <nz-time-picker [nzDisabledHours]="disabledHours" [nzDisabledMinutes]="disabledMinutes" [nzMinuteStep]="30" 
                          [nzNowText]="'Şimdi'" [nzOkText]="'Bitti'" [(ngModel)]="selectedAnnouncement.shareDate" nzFormat="HH:mm">
          </nz-time-picker>
        </nz-row>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Durum">
        <nz-select *ngIf="status != 2" [(ngModel)]="selectedAnnouncement.status" style="width: 40%;">
          <nz-option [nzLabel]="'Beklemede'" [nzValue]="1" [nzDisabled]="status != 1"></nz-option>
          <nz-option [nzLabel]="'Pasif'" [nzValue]="2"></nz-option>
          <nz-option [nzLabel]="'Yayında'" [nzValue]="3" [nzDisabled]="status == 1"></nz-option>
        </nz-select>
        <nz-select *ngIf="status == 2" [(ngModel)]="selectedAnnouncement.status" style="width: 40%;">
          <nz-option [nzLabel]="'Pasif'" [nzValue]="2"></nz-option>
          <nz-option [nzLabel]="'Aktif'" [nzValue]="4"></nz-option>
        </nz-select>
      </nz-descriptions-item>
    </nz-descriptions>
  </nz-spin>
</ng-template>