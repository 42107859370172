import {Serializable} from "../serializable";

export class UserInfoResponse extends Serializable{
  id!:number;
  firstName!:string;
  lastName!:string;
  phone!:string;
  email!:string;
  userRole!:string;
  corporate!:number;
}
