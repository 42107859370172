import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlConfig } from "../config/url";
import { map } from "rxjs";
import { EmailLogQueryResponse } from "../model/response/email.log.query.response";

@Injectable()
export class EmailLogService {
    constructor(private http: HttpClient) {
    }

    query() {
        return this.http.get(UrlConfig.transactionQueryEmailLog).pipe(map(m => {
            return new EmailLogQueryResponse(m);
        }));
    }

}