<div nz-row style="margin: 20px 5px 20px 0">
  <div nz-col nzSpan="10"><nz-input-group nzCompact>
    <nz-select [(ngModel)]="searchType" style="width: 150px" (ngModelChange)="searchTypeChange()">
      <nz-option [nzLabel]="'Ad Soyad'" [nzValue]="'name'"></nz-option>
      <nz-option [nzLabel]="'ID'" [nzValue]="'id'"></nz-option>
      <nz-option [nzLabel]="'Telefon'" [nzValue]="'phone'"></nz-option>
      <nz-option [nzLabel]="'T.C Kimlik No'" [nzValue]="'tc'"></nz-option>
      <nz-option [nzLabel]="'E-Posta'" [nzValue]="'email'"></nz-option>
      <nz-option [nzLabel]="'Firma'" [nzValue]="'corporate'"></nz-option>
      <nz-option [nzLabel]="'Durum'" [nzValue]="'status'"></nz-option>
      <nz-option [nzLabel]="'Rol'" [nzValue]="'role'"></nz-option>
    </nz-select>
    <nz-select [(ngModel)]="searchValue" style="width: 160px" *ngIf="searchType=='role'">
      <nz-option [nzLabel]="'Yönetici'" [nzValue]="'admin'"></nz-option>
      <nz-option [nzLabel]="'Kullanıcı'" [nzValue]="'user'"></nz-option>
      <nz-option [nzLabel]="'Ön Kayıtlı Kullanıcı'" [nzValue]="'pre_user'"></nz-option>
    </nz-select>
    <nz-select [(ngModel)]="searchValue" style="width: 150px" *ngIf="searchType=='status'">
      <nz-option [nzLabel]="'Aktif'" [nzValue]="1"></nz-option>
      <nz-option [nzLabel]="'Pasif'" [nzValue]="2"></nz-option>
      <nz-option [nzLabel]="'Beklemede'" [nzValue]="3"></nz-option>
      <nz-option [nzLabel]="'Kapama Talep Edildi'" [nzValue]="4"></nz-option>
      <nz-option [nzLabel]="'Bloke Edildi'" [nzValue]="5"></nz-option>
    </nz-select>
    <input type="text" nz-input [(ngModel)]="searchValue" style="width:50%;"  *ngIf="searchType!=='role' && searchType!=='status'"/>
  </nz-input-group></div>
  <div nz-col nzSpan="3"><button nz-button  nzType="primary" (click)="search()">Ara</button>
    <button nz-button  nzType="default" style="margin-left: 5px" (click)="reset()">Sıfırla</button>
  </div>
  <div nz-row>
    <div nz-col style="display: flex; align-items: center;">
      <th style="margin-left: 25%; margin-right: 16px;">Kullanıcı Verileri</th>
      <button (click)="downloadUsers()" nz-button nzShape= "circle" nzType= "primary" style="font-weight: bold; margin-right: 10px">
        <i class="icon"
          nz-icon
          nzType="download"></i>
      </button>
    </div>
    <div nz-col style="display: flex; align-items: center;">
      <th style="margin-left: 25%; margin-right: 16px;">Transaction Verileri</th>
      <button (click)="downloadTransaction()" nz-button nzShape= "circle" nzType= "primary" style="font-weight: bold; margin-right: 10px; justify-items: center;">
        <i class="icon"
          nz-icon
          nzType="download"></i>
      </button>
    </div>
  </div>
</div>

<nz-table #editRowTable [nzData]="listOfDisplayData" [nzPageSize]="15" nzBordered nzShowSizeChanger [nzLoading]="dataLoading">
  <thead>
  <tr>
    <th>ID</th>
    <th>Ad Soyad
   <!--   <nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menu"
                         style="float: right;margin-top: 5px">
        <i [ngClass]="[searchValue.length > 0 && !visible?'orange':''] " nz-icon nzType="search"
           style="font-size: 16px; "></i>
      </nz-filter-trigger>-->
    </th>
    <th>Telefon</th>
    <th>E-Posta</th>
    <th>Firma</th>
    <th>Rol</th>
    <th>Durum</th>
    <th nzWidth="5%">İşlemler</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of editRowTable.data" [ngClass]="data.userRole.value=='pre_user'?'orange':''">
    <ng-container>
      <td>{{ data.id }}</td>
      <td><i *ngIf="data.userRole.value=='admin'"
             class="icon yellow"
             nz-icon
             nzTheme="fill"
             nzType="star"></i>
        <i *ngIf="data.userRole.value!='admin'" style="margin-right: 16px"> </i>

        {{ data.firstName }} {{ data.lastName }}
      <td>
        <div>{{ data.phone|mask: '(000) 000 00 00' }}</div>
      </td>
      <td>{{ data.email }}</td>
      <td>{{ data.corporate }}</td>
      <td [ngSwitch]="data.status">
        <nz-tag *ngSwitchCase="1" [nzColor]="'#87d068'">Aktif</nz-tag>
        <nz-tag *ngSwitchCase="2" [nzColor]="'#ff2432'">Pasif</nz-tag>
        <nz-tag *ngSwitchCase="3" [nzColor]="'#66ffdb'">Beklemede</nz-tag>
        <nz-tag *ngSwitchCase="4" [nzColor]="'#f50'">Kapama Talep Edildi</nz-tag>
        <nz-tag *ngSwitchCase="5" [nzColor]="'#22aadb'">Bloke Edildi</nz-tag>
      </td>
      <td>{{ data.userRole.text }}</td>
      <td>
        <a (click)="showModal(data)" class="green" style="font-weight: bold; margin-left: 10px"><i class="icon"
                                                                                                   nz-icon
                                                                                                   nzType="eye"></i></a>
      </td>
    </ng-container>

  </tr>
  </tbody>
</nz-table>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
<!--    <div class="search-box">
      <input [(ngModel)]="searchValue" nz-input placeholder="Ad veya Soyad ara" type="text"/>
      <button (click)="search()" class="search-button" nz-button nzSize="small" nzType="primary">Ara</button>
      <button (click)="reset()" nz-button nzSize="small">Reset</button>
    </div>-->
  </div>
</nz-dropdown-menu>

<nz-modal (nzOnCancel)="modalCancel()" (nzOnOk)="modalOk()" [(nzVisible)]="userModalVisible" nzOkText="Kaydet">

  <ng-container *nzModalTitle>
    <span style="margin-right: 10px">Kullanıcı Bilgileri</span>
    <a (click)="startEdit(selectedUser.id)" *ngIf="!editing" style="font-weight: bold"><i
      class="icon" nz-icon
      nzType="edit"></i></a>
    <a (click)="cancelEdit(selectedUser.id)" *ngIf="editing" style="font-weight: bold"><i
      class="icon" nz-icon
      nzType="close"></i></a>
  </ng-container>
  <ng-container *nzModalContent>

    <ng-container *ngIf="!editing; else editTemplate">

      <nz-descriptions nzBordered nzSize="small">
        <nz-descriptions-item nzSpan="4" *ngIf="selectedUser.userRole.value!='pre_user'" nzTitle="Ad">{{selectedUser.firstName}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" *ngIf="selectedUser.userRole.value!='pre_user'" nzTitle="Soyad">{{selectedUser.lastName}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" *ngIf="selectedUser.userRole.value!='pre_user'" nzTitle="T.C Kimlik No">{{selectedUser.tckn}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4"
                              nzTitle="Telefon">{{selectedUser.phone|mask: '(000) 000 00 00'}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" *ngIf="selectedUser.userRole.value!='pre_user'" nzTitle="E-Posta">{{selectedUser.email}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" *ngIf="selectedUser.userRole.value!='pre_user'" nzTitle="Sicil No:">{{selectedUser.employeeNo}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" *ngIf="selectedUser.userRole.value!='pre_user'" nzTitle="Firma">{{selectedUser.corporate}}</nz-descriptions-item>
        <ng-container [ngSwitch]="selectedUser.status">
          <nz-descriptions-item nzSpan="4" nzTitle="Durum" *ngSwitchCase="1">Aktif</nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" nzTitle="Durum" *ngSwitchCase="2">Pasif</nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" nzTitle="Durum" *ngSwitchCase="3">Beklemede</nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" nzTitle="Durum" *ngSwitchCase="4">Kapama Talep Edildi</nz-descriptions-item>
          <nz-descriptions-item nzSpan="4" nzTitle="Durum" *ngSwitchCase="5">Bloke Edildi</nz-descriptions-item>
        </ng-container>
        <nz-descriptions-item nzSpan="4" nzTitle="Rol">{{selectedUser.userRole.text}}</nz-descriptions-item>
       <!-- <nz-descriptions-item *ngIf="selectedUser.userRole.value!='pre_user'" nzSpan="4"
                              nzTitle="Doğum Tarihi">{{selectedUser.birthDate|date:'dd.MM.YYYY'}}</nz-descriptions-item>
        <nz-descriptions-item nzSpan="4" *ngIf="selectedUser.userRole.value!='pre_user'" nzTitle="Doğum Yeri">{{selectedUser.birthPlace}}</nz-descriptions-item>-->
        <nz-descriptions-item nzSpan="4"
                              nzTitle="Oluşturulma Zamanı">{{selectedUser.createDate|date:'dd.MM.yyyy HH:mm'}}</nz-descriptions-item>

      </nz-descriptions>
    </ng-container>

  </ng-container>

</nz-modal>
<ng-template #editTemplate>
  <nz-spin [nzSpinning]=isUpdating nzTip="Lütfen bekleyin...">

    <nz-descriptions nzBordered nzSize="small">
      <nz-descriptions-item nzSpan="4" *ngIf="selectedUser.userRole.value!='pre_user'" nzTitle="Ad"><input [(ngModel)]="selectedUser.firstName" nz-input type="text"/></nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" *ngIf="selectedUser.userRole.value!='pre_user'" nzTitle="Soyad"><input [(ngModel)]="selectedUser.lastName" nz-input type="text"/></nz-descriptions-item>
                                                                                                                 
      <nz-descriptions-item nzSpan="4" *ngIf="selectedUser.userRole.value!='pre_user'" nzTitle="T.C Kimlik No">{{selectedUser.tckn}}</nz-descriptions-item>
      <nz-descriptions-item nzSpan="4"  nzTitle="Telefon"><input [(ngModel)]="selectedUser.newPhone"
                                                                mask="(000) 000 00 00" nz-input type="tel"/>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" *ngIf="selectedUser.userRole.value!='pre_user'" nzTitle="E-Posta"><input [(ngModel)]="selectedUser.email" nz-input type="email"/>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" *ngIf="selectedUser.userRole.value!='pre_user'" nzTitle="Sicil No:"><input [(ngModel)]="selectedUser.employeeNo" nz-input type="text"/>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="5" nzTitle="Firma">
          <nz-select [(ngModel)]="selectedUser.corporateId" nzShowSearch nzPlaceHolder="Firma Ekle" style="width: 60%;">
            <nz-option
              *ngFor="let option of corporateList"
              [nzLabel]="option.text"
              [nzValue]="option.id"
            ></nz-option>
          </nz-select>
        </nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Durum">
        <nz-select [(ngModel)]="selectedUser.status">
          <nz-option [nzLabel]="'Aktif'" [nzValue]="1"></nz-option>
          <nz-option [nzLabel]="'Pasif'" [nzValue]="2"></nz-option>
          <nz-option [nzLabel]="'Beklemede'" [nzValue]="3"></nz-option>
          <nz-option [nzLabel]="'Kapama Talep Edildi'" [nzValue]="4"></nz-option>
          <nz-option [nzLabel]="'Bloke Edildi'" [nzValue]="5"></nz-option>
        </nz-select>
      </nz-descriptions-item>
      <nz-descriptions-item nzSpan="4" nzTitle="Rol">{{selectedUser.userRole.text}}</nz-descriptions-item>
<!--      <nz-descriptions-item nzSpan="4" *ngIf="selectedUser.userRole.value!='pre_user'" nzTitle="Doğum Tarihi">
        <nz-date-picker [nzFormat]="'dd.MM.yyyy'" *ngIf="selectedUser.userRole.value!='pre_user'" [(ngModel)]="selectedUser.birthDate"></nz-date-picker>
      </nz-descriptions-item>

             <nz-descriptions-item nzSpan="4" *ngIf="selectedUser.userRole.value!='pre_user'" nzTitle="Doğum Yeri"><input [(ngModel)]="selectedUser.birthPlace" nz-input
                                                                                                                   type="text"/></nz-descriptions-item>
       -->

      <nz-descriptions-item nzSpan="4" nzTitle="Oluşturulma Zamanı">{{selectedUser.createDate|date:'dd.MM.yyyy HH:mm'}}</nz-descriptions-item>

    </nz-descriptions>


  </nz-spin>
</ng-template>
