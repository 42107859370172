import {IntValue} from "../int.value";

export class CampaignUpdateRequest {
  campaignId!:number;
  title!:string;
  description!:string;
  conditions!:string;
  corporate!:IntValue;
  commission!:number;
  cashBackRatio!:number;
  startDate!:Date;
  endDate!:Date;
  status!:boolean;
  locations!:string[];
  campaignType!:string;
  corporates!:number[];
  webSite!:string;
  couponLimit!:number;
  notificationEnabled!:boolean;
  notificationTitle!:string;
  notificationContent!:string;
  notificationDate!:Date;
  tag!:boolean;
}
