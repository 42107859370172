import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UrlConfig} from "../config/url";
import {LoginResponse} from "../model/response/login.response";
import {map} from "rxjs/operators";
import {LocalStorageService} from "./local.storage.service";
import { OtpLoginResponse } from "../model/response/otp.login.response";


@Injectable()
export class LoginService {
  constructor(private http: HttpClient, private localStorage: LocalStorageService) {
  }

  username: string = '';
  password: string = '';

  login(request: { username: string, password: string, key: string }) {
    return this.http.post(UrlConfig.login, request).pipe(
      map((data) => {
        var ret = new LoginResponse(data);
        if(ret.token!=null){
          this.localStorage.set('token', ret.token);
          this.localStorage.set('expire', ret.expireTime.toString());
        }
        return ret;
      }));
  }

  otpLogin(request: { username: string, password: string }) {
    return this.http.post(UrlConfig.otpLogin, request).pipe(
      map((data) => {
        var ret = new OtpLoginResponse(data);
        return ret.isOk();
      }));
  }

  isTokenExpired() {
    var expire = this.localStorage.get('expire');
    return (expire != null && Number(expire) < Date.now()) || expire == null;
  }
}
