import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CorporateService} from "../../service/corporate.service";
import {Corporate} from "../../model/corporate";
import {CorporateSaveRequest} from "../../model/request/corporate.save.request";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {DomSanitizer} from "@angular/platform-browser";
import {lastValueFrom} from "rxjs";
import { IntValue } from 'src/app/model/int.value';
import { CampaignService } from 'src/app/service/campaign.service';
import { ModulesService } from 'src/app/service/modules.service';

@Component({
    selector: 'app-corporate',
    templateUrl: './corporate.component.html',
    styleUrls: ['./corporate.component.less']
})
export class CorporateComponent implements OnInit {
    listOfData: Corporate[] = [];
    selectedCorporate!: Corporate;
    newCorporate!: CorporateSaveRequest;
    corporateModalVisible: boolean = false;
    newCorporateModalVisible: boolean = false;
    isEditing: boolean = false;
    isUpdating: boolean = false;

    mailInputVisible = false;
    mailInputValue = '';
    totalActiveCorporate = 0;
    totalPassiveCorporate = 0;
    dataLoading = false;
    addRegistrationModalVisible = false;
    registrationFile?: File;
    showRegistrationDetail = false;
    isNotDefaultRegistration = false;
    registrationStyle = 'atLast'
    campaignList!: IntValue[];
    selectedCorporateCampaignList!: number[];
    list!: number[];
    modulesList!: IntValue[];

    @ViewChild('inputElement', {static: false}) mailInputElement?: ElementRef;
    selectedLogo!: File;

    constructor(private corporateService: CorporateService, private notificationService: NzNotificationService, private sanitizer: DomSanitizer, private campaignService: CampaignService, private modulesService: ModulesService) {

    }

    ngOnInit(): void {
        this.query();
        this.campaignService.list().subscribe(c => {
            this.campaignList = c.list;
        });
        this.modulesService.list().subscribe(m => {
            this.modulesList = m.list;
        });
    }

    query() {
        this.dataLoading = true;
        this.corporateService.query().subscribe(data => {
            this.listOfData = data.corporates;
            this.totalPassiveCorporate = this.listOfData.filter((c) => !c.status).length
            this.totalActiveCorporate = this.listOfData.length - this.totalPassiveCorporate;
            this.dataLoading = false;
        });

    }

    showModal(corporate: Corporate) {
        this.selectedCorporate = new Corporate();
        this.selectedCorporate.clone(corporate);
        
        this.corporateService.corporateCampaigns(this.selectedCorporate.id).subscribe(s => {
            if (s.isOk()) {
                this.selectedCorporate.campaigns = s.list;
                lastValueFrom(this.corporateService.getLogo(corporate.id)).then(v => {
                    if (v.size != 0) {
                        const reader = new FileReader();
                        reader.onload = (e) => this.selectedCorporate.logo = e.target!.result;
                        reader.readAsDataURL(v);
                    }
                    this.selectedCorporateCampaignList = this.selectedCorporate.campaigns.map(m => m.id);
                    this.corporateModalVisible = true;
        
                });
                this.corporateModalVisible = true;
            }
        });
    }

    modalOk() {
        var req = new CorporateSaveRequest();
        req.id = this.selectedCorporate.id;
        req.campaigns = this.selectedCorporateCampaignList;
        req.contactEmail = this.selectedCorporate.contactEmail;
        req.contactName = this.selectedCorporate.contactName;
        req.contactPhone = this.selectedCorporate.contactPhone;
        req.mailDomains = this.selectedCorporate.mailDomains;
        req.name = this.selectedCorporate.name;
        req.shortName = this.selectedCorporate.shortName;
        req.status = this.selectedCorporate.status;
        req.vkn = this.selectedCorporate.vkn;
        req.modules = this.selectedCorporate.modules;
        this.updateCorporate(req);
    }

    modalCancel() {
        this.corporateModalVisible = false;
        this.isEditing = false;
    }

    startEdit(corporate: Corporate) {
        this.isEditing = true;
        this.selectedLogo = new File([], "");
    }

    cancelEdit(corporate: Corporate) {
        this.isEditing = false;
    }

    newCorporateModalShow() {
        this.newCorporateModalVisible = true;
        this.newCorporate = new CorporateSaveRequest();
        this.mailInputValue = '';
        this.newCorporate.mailDomains = [];
        this.newCorporate.campaigns = this.campaignList.map(m => m.id);
        this.newCorporate.modules = [];
    }

    newCorporateModalCancel() {
        this.newCorporateModalVisible = false;
    }

    newCorporateModalSave() {
        this.newCorporateModalVisible = false;
    }

    showInput(): void {
        this.mailInputVisible = true;

        setTimeout(() => {
            this.mailInputElement?.nativeElement.focus();
        }, 10);
    }

    handleClose(removedTag: {}): void {
        this.newCorporate.mailDomains = this.newCorporate.mailDomains.filter(tag => tag !== removedTag);
    }

    sliceTagName(tag: string): string {
        const isLongTag = tag.length > 20;
        return isLongTag ? `${tag.slice(0, 20)}...` : tag;
    }

    handleInputConfirm(isUpdate: boolean): void {
        if (this.mailInputValue && this.newCorporate != null && this.newCorporate.mailDomains.indexOf(this.mailInputValue) === -1 && !isUpdate) {
            this.newCorporate.mailDomains = [...this.newCorporate.mailDomains, this.mailInputValue];
        } else if (this.mailInputValue && this.selectedCorporate.mailDomains.indexOf(this.mailInputValue) === -1 && isUpdate) {
            this.selectedCorporate.mailDomains = [...this.selectedCorporate.mailDomains, this.mailInputValue];
        }
        this.mailInputValue = '';
        this.mailInputVisible = false;
    }


    saveNewCorporate() {
        this.corporateService.save(this.newCorporate).subscribe(r => {
                if (r.isOk()) {
                    if (this.selectedLogo != null && this.selectedLogo.size > 10) {

                        this.corporateService.uploadLogo(this.selectedLogo, r.id).subscribe(() => {
                            this.newCorporateModalVisible = false;
                            this.query();
                        });
                    } else {
                        this.newCorporateModalVisible = false;
                        this.query();
                    }
                } else {
                    this.notificationService.error('Firma Ekeleme Hatası', 'Lütfen tüm alanları eksiksiz doldurunuz.')
                }
            }, error => this.notificationService.error('Firma Ekeleme Hatası', 'Lütfen tüm alanları eksiksiz doldurunuz.')
        );
    }

    onFileChanged(event: any) {
        this.selectedLogo = event.target.files[0];
    }

    handleUpdateClose(removedTag: {}): void {
        this.selectedCorporate.mailDomains = this.selectedCorporate.mailDomains.filter(tag => tag !== removedTag);
    }

    updateCorporate(request: CorporateSaveRequest) {
        this.isUpdating = true;
        this.corporateService.update(request).subscribe(r => {
                if (r.isOk()) {
                    this.isUpdating = false;
                    if (this.selectedLogo != null && this.selectedLogo.size > 10) {
                        this.corporateService.uploadLogo(this.selectedLogo, this.selectedCorporate.id).subscribe(() => {
                            this.query();
                            this.corporateModalVisible = false;
                            this.isEditing = false;
                        });
                    } else {
                        this.query();
                        this.corporateModalVisible = false;
                        this.isEditing = false;
                    }


                } else {
                    this.notificationService.error('Firma Günelleme Hatası', 'Lütfen tüm alanları eksiksiz doldurunuz.');
                    this.isUpdating = false;
                }
            }, error => {
                this.notificationService.error('Firma Güncelleme Hatası', 'Lütfen tüm alanları eksiksiz doldurunuz.');
                this.isUpdating = false;
            }
        );
    }

    showAddRegistrationModal(corporate:Corporate){
        this.isNotDefaultRegistration = false;
        this.registrationStyle = "atLast";
        this.showRegistrationDetail = false;
        this.selectedCorporate = corporate;
        this.registrationFile = undefined;
        this.addRegistrationModalVisible = true;
    }

    addRegistrationModalOk(){
        if(!this.showRegistrationDetail){
            this.isUpdating = true;
            if (this.selectedCorporate != null  && this.registrationFile != undefined){
                if(!this.isNotDefaultRegistration){
                    this.registrationStyle = "";
                }
                this.corporateService.addRegistration(this.registrationFile, this.selectedCorporate.id,
                    this.registrationStyle, !this.isNotDefaultRegistration).subscribe(s =>{
                    if(s.isOk()){
                        this.isUpdating = false;
                        this.addRegistrationModalVisible = false;
                        this.notificationService.success('', 'Sicil numaraları tanımlandı.');
                    }
                });
            }else{
                this.isUpdating = false;
                this.notificationService.error('Hata', 'Tüm alanları eksiksiz doldurun.');
            }
        }else{
            this.addRegistrationModalVisible = false;
        }
    }

    addRegistrationModalCancel(){
        this.addRegistrationModalVisible = false;
    }

    getRegistrationFile(event:any){
        let file = event.target.files[0];
        this.registrationFile = file;
    }

    downloadRegistrationFile(){
        this.isUpdating = true;
        lastValueFrom(this.corporateService.downloadRegistrationFile(this.selectedCorporate.id)).then(v=>{
            if(v.size != 0){
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(v);
                link.download = this.selectedCorporate.shortName  + "-sicil_detay.xlsx";
                link.click();
                this.addRegistrationModalVisible = false;
                this.isUpdating = false;
            }else{
                this.notificationService.error("Hata", "Tanımlı veri bulunamadı.");
                this.isUpdating = false;
            }
        });
    }

    downloadCorporates() {
        this.isUpdating = true;
            lastValueFrom(this.corporateService.getCorporateFile()).then(v => {
                if (v.size != 0) {
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(v);
                    link.download = "firma_rapor.xlsx";
                    link.click();
                    this.isUpdating = false;
                }
            });
      }
}
